/* -------------------------------------------
    Custom Icons
---------------------------------------------- */
// User Icon

// Menu Icon
.menu-icon {
	display: inline-block;
	color: #fff;
	width: 1.7rem;

	&::before,
	&::after {
		display: block;
		content: '';
		width: 100%;
	}

	&::before {
		height: 6px;
		border-top: 2px solid;
		border-bottom: 2px solid;
	}

	&::after {
		height: 4px;
		border-bottom: 2px solid;
	}
}

//  Close Icon
// .d-icon-times {
// 	display: block;
// 	position: relative;
// 	width: 30px;
// 	height: 30px;
// 	transform: rotateZ(45deg);

// 	&::before,
// 	&::after {
// 		display: block;
// 		position: absolute;
// 		background-color: #ccc;
// 		content: '';
// 	}

// 	&::before {
// 		height: 2px;
// 		width: 100%;
// 		left: 0;
// 		top: calc(50% - 1px);
// 	}

// 	&::after {
// 		height: 100%;
// 		width: 2px;
// 		top: 0;
// 		left: calc(50% - 1px);
// 	}
// }

.minicart-icon {
	display: inline-block;
	position: relative;
	/* width: 30px; */
	width: 2.5em;
	/* height: 30px; */
	height: 2.5em;
	margin-top: 1px;
	border: solid 2px;
	border-radius: 2px;
	text-align: center;
	transition: background .4s, border-color .4s;

	&::before {
		content: '';
		position: absolute;
		/* top: -9px; */
		top: -.75em;
		/* left: 7px; */
		left: .585em;
		/* width: 12px; */
		width: 1em;
		/* height: 6px; */
		height: .5em;
		border-radius: 10px 10px 0 0;
		border: inherit;
		border-bottom: none;
		transition: transform .4s;
	}
}