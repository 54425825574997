/* -------------------------------------------
    Products 
        - Default
        - Classic
        - Slideup
            - SlideUp-Content
            - SlideUp
        - Gallery
        - List
            - List
            - Small
        
        - Product Filters
---------------------------------------------- */
// Variables
@include set-default(
  (
    product: (
      font-family: $font-family,
      font-size: 1.4rem,
      color: #999,
      background-color: $white-color,
      body: (
        padding-top: 1.4rem,
        padding-bottom: 2rem,
      ),
      label: (
        margin-bottom: 0.5rem,
        padding: 0.5rem 1.1rem,
        font-family: false,
        font-size: 1.1rem,
        border-radius: 2px,
        font-weight: 600,
        line-height: 1,
        letter-spacing: false,
        color: #fff,
        text-transform: uppercase,
        new: (
          color: false,
          background: $primary-color,
        ),
        sale: (
          color: false,
          background: $sold-out-back,
        ),
        top: (
          color: false,
          background: $primary-color,
        ),
        stock: (
          color: false,
          background: #999,
        ),
        sold: (
          color: false,
          background: $sold-out-back,
        ),
        bestseller: (
          color: false,
          background: $dark-green,
        ),
      ),
      category: (
        margin-bottom: 0.5rem,
        font-family: false,
        font-size: 1rem,
        font-weight: 400,
        line-height: 1,
        letter-spacing: false,
        text-transform: uppercase,
        color: false,
        white-space: nowrap,
        text-overflow: ellipsis,
        overflow: hidden,
      ),
      name: (
        margin-bottom: 0.3rem,
        font-family: inherit,
        font-size: 1.4rem,
        font-weight: 400,
        line-height: false,
        letter-spacing: 0.05rem,
        text-transform: false,
        color: $dark-color,
        padding-right: 20px,
      ),
      price: (
        display: block,
        align-items: center,
        flex-wrap: wrap,
        margin-bottom: 0.3rem,
        font-family: false,
        font-size: 1.6rem,
        font-weight: 600,
        line-height: 1.86,
        letter-spacing: false,
        text-transform: false,
        color: #222,
      ),
      rating: (
        margin-bottom: 1.2rem,
        font-size: 11px,
        _star-color: $secondary-color,
      ),
      variation: (
        width: 2.6rem,
        height: 2.6rem,
        color: #222,
        transition: 0.3s,
        _active-border: false,
        _active-box-shadow: 0 0 0 3px #999,
        _active-outline: #fff solid 2px,

        // custom types
        _color-border: 1px solid $border-color,
        _image-border: 0,
      ),
      list-sm: (
        name: (
          color: $dark-color,
        ),
        _image-width: 10rem,
      ),
    ),
  )
);

.equal-height {
  .product {
    height: 100%;
  }
}

.product-widget-wrapper {
  .owl-stage {
    padding-left: 0 !important;
  }
}

.product-widget-wrapper:nth-child(odd) {
  background-color: $background-color-2;
}

// Product Shadow
.product-shadow {
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0 15px 25px -20px rgba(0, 0, 0, 0.5);
  }

  // .product-details {
  //     padding-left: .5rem;
  //     padding-right: .5rem;
  // }
}

// Product Label Round
.product-label-round {
  .product-label {
    padding: 0.5rem 1.1rem;
    border-radius: 1rem;
  }
}

// Default
.product-wrap {
  margin-bottom: 2rem;
}

.product {
  position: relative;
  @include print_css(product);
  transition: 0.3s;
}

.product-media:hover {
  span:first-child {
    opacity: 0;
  }

  span:last-child {
    opacity: 1;
  }
}

// Product Media
.product-media {
  position: relative;
  margin-bottom: 0;

  &:not(.pure-media)::before {
    content: "";
    display: block;
    padding-top: 100%;
    background-color: #f4f4f4;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
    transition: all 0.5s;
  }

  span {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    left: 0;
    transition: all 0.5s;
  }

  span:last-child {
    opacity: 0;
    // object-fit: cover;
  }

  span:first-child {
    // position: relative;
    opacity: 1;
  }

  transition: box-shadow 0.3s;
}

.product-loading-overlay {
  &:before,
  &:after {
    content: "";
    display: block;
  }

  &:before {
    padding-top: 155%;
    background-repeat: no-repeat;
    background-image: linear-gradient(#f4f4f4 105%, transparent 0),
      linear-gradient(#f4f4f4 12px, transparent 0),
      linear-gradient(#f4f4f4 16px, transparent 0),
      linear-gradient(#f4f4f4 12px, transparent 0),
      linear-gradient(#f4f4f4 16px, transparent 0);
    background-size: 100% 73%, 50% 12px, 73% 16px, 50% 12px, 40% 16px;
    background-position: left 0, left calc(73% + 20px), left calc(73% + 44px),
      left calc(73% + 66px), left calc(73% + 92px);
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 40px;
    height: 40px;
    top: calc(35% - 20px);
    left: calc(50% - 20px);
    -webkit-animation: spin 650ms infinite linear;
    animation: spin 650ms infinite linear;
    border-radius: 75%;
    border: 2px solid $primary-color !important;
    border-bottom-color: transparent !important;
    z-index: 3;
  }
}

.loader-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  cursor: pointer;

  .common-loading-overlay {
    content: "";
    display: block;
    position: absolute;
    width: 40px;
    height: 40px;
    top: 50%;
    left: 50%;
    -webkit-animation: spin 650ms infinite linear;
    animation: spin 650ms infinite linear;
    border-radius: 75%;
    border: 2px solid $primary-color !important;
    border-bottom-color: transparent !important;
    z-index: 9999;
  }
}

// Product Label
.product-label-group {
  position: absolute;
  right: 0px;
  top: 0px;
  max-width: 10rem;
}

.product-tags-group {
  position: absolute;
  left: 0px;
  top: 0px;
  max-width: 10rem;
}

.product-label {
  display: block;
  text-align: center;
  padding: 0.5rem 0.7rem;
  font-size: 1.1rem;
  letter-spacing: 0.5px;
  line-height: 1;
  color: #fff;

  &.label-new {
    @include print_css(product, label, new);
  }

  &.label-sold {
    border-top-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    @include print_css(product, label, sold);
  }

  &.label-best-seller {
    border-top-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    @include print_css(product, label, bestseller);
  }

  &.label-sale {
    border-top-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
    @include print_css(product, label, sale);
  }

  &.label-top {
    @include print_css(product, label, top);
  }

  &.label-stock {
    @include print_css(product, label, stock);
  }
}

// Product Icon Button
.btn-product-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.6rem;
  height: 3.6rem;
  margin-bottom: 0.5rem;
  border: 1px solid #e1e1e1;
  border-radius: 50%;
  background-color: white;
  color: #999;

  font: {
    size: 1.6rem;
    weight: 700;
  }

  transition: border-color 0.3s, color 0.3s, background-color 0.3s;

  &.btn-wishlist i {
    // margin-top: .3rem;
    // margin-left: .1rem;
  }

  &:hover {
    border-color: $primary-color;
    color: #fff;
    background-color: $primary-color;
  }

  i {
    margin-bottom: 2px;
  }

  &.btn-quickview i {
    font-size: inherit;
  }

  &.btn-compare i {
    font-size: 1.8rem;
  }
}

.product-list .btn-product-icon i {
  margin-bottom: 1px;
}

// Product Button
.btn-product {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 1.14em 0;

  font-family: $font-family;

  font: {
    size: 1.4rem;
    weight: 700;
  }

  line-height: 1.2;
  text-transform: uppercase;
  transition: opacity 0.3s;
}

// QuickView Button
.btn-quickview {
  opacity: 1;
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }

  i {
    //transform: translateX(1em);
    font: {
      size: 1.4rem;
    }
  }
}

.btn-sold-out {
  background-color: rgb(249, 232, 232);
  color: #c20000;
  letter-spacing: 1px;

  &:hover {
    color: #c20000;
  }
}

// WishList Button
.btn-wishlist {
  line-height: 1;

  &.loading {
    &::before,
    &::after {
      z-index: 1;
    }
  }
}

// Product Action Vertical Container
.product-action-vertical {
  position: absolute;
  top: 12px;
  right: 12px;
  transition: opacity 0.3s, visibility 0.3s;
  opacity: 0;
  visibility: hidden;

  .btn-wishlist.loading {
    overflow: hidden;
  }
}

// Product Action Container
.product-action {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  background-color: transparent;
  z-index: 10;
  transition: opacity 0.3s, visibility 0.3s;
  opacity: 0;
}

// Product Details
.product-details {
  position: relative;
  @include print_css(product, body);
  padding: 1rem 0 1rem;

  &.card {
    padding: 1rem 1.5rem 1rem;
  }

  .details-wrapper {
    height: 11.4rem;
    margin-bottom: 2rem;
  }

  > .btn-wishlist {
    position: absolute;
    top: 1.2rem;
    right: 0;
    color: #999;
    z-index: 1;

    font: {
      size: 1.6rem;
    }

    i {
      display: inline-block;
      margin: 0;
      transition: transform 0.3s;
    }

    &:hover {
      i {
        transform: rotateY(180deg);
      }

      color: $primary-color;
    }
  }

  > :last-child {
    margin-bottom: 0;
  }

  .product-action {
    position: relative;
    opacity: 1;
    visibility: visible;
  }

  .product-tags {
    font-size: 1.2rem;
    color: $success-color;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 3.5rem;
  }
}

// Product Category
.product-cat {
  @include print_css(product, category);

  a {
    &:hover {
      color: $primary-color;
    }
  }
}

// Product Name
.product-name {
  @include print_css(product, name);
  text-overflow: ellipsis;

  a {
    // transition: color .3s;
    &:hover {
      color: $primary-color;
    }
  }
}

.product-collection-large {
  .product-card-title {
    margin-top: 12px;
    font-size: 1.4rem !important;
    display: -webkit-box !important;
    -webkit-line-clamp: unset !important;
    -webkit-box-orient: vertical !important;
    overflow: hidden !important;
  }

  .details-wrapper {
    height: 5rem;
    margin-bottom: 5rem;
  }
}

.product-card-title {
  margin-top: 12px;
  font-size: 1.4rem !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
}

// Product Tag
.product-tag {
  font-size: 1.2rem;
  text-overflow: ellipsis;
  color: $success-color;
  text-align: center !important;
}

// Product Price
.product-price {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @include print_css(product, price);

  .old-price {
    color: #aaa;
  }

  .new-price,
  .product-save {
    text-decoration: none;

    span {
      font-weight: 400;
    }
  }
}

// Product Rating
.ratings-container {
  display: flex;
  align-items: center;
  margin-top: 4px;
  @include print_css(product, rating);

  .rating-product-list {
    padding-bottom: 3px;
  }

  .rating {
    font-size: 1.3rem;
    text-align: center;
    line-height: 1;
  }
}

.ratings-full {
  cursor: pointer;
}

.ratings-full,
.ratings {
  position: relative;
  font-family: "riode";
}

.ratings-full {
  & + .rating-reviews {
    margin-left: 1rem;
  }

  &::before {
    // content: "\e955 " "\e955 " "\e955 " "\e955 " "\e955";
    color: rgba(0, 0, 0, 0.16);
    font-family: "riode";
  }

  &:hover {
    .tooltiptext {
      visibility: visible;
      opacity: 1;
    }
  }
}

.ratings {
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  overflow: hidden;

  &::before {
    content: "\e955 ""\e955 ""\e955 ""\e955 ""\e955";
    @include css(color, product, rating, _star-color);
    font-family: "riode";
  }
}

.rating-reviews {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.3rem;
  color: #aaa;
  letter-spacing: 0;
  text-transform: capitalize;
  line-height: 1;

  &:hover {
    color: $primary-color;
  }
}

// Product Variables
.product-variations {
  display: flex;

  // issue element-products
  .ratings-container + & {
    margin-top: -5px;
  }

  > a:not(.size-guide) {
    display: inline-block;
    position: relative;
    margin: 3px 9px 3px 0;
    padding: 0 5px;
    border-radius: 2px;
    background-color: transparent;
    background-size: cover;
    font-size: 1.2rem;
    font-family: inherit;
    text-align: center; // issue: if use only button, no need
    vertical-align: middle;
    cursor: pointer;
    @include print_css(product, variation);
    @include css(line-height, product-single, variation, height);

    &:last-child {
      margin-right: 0;
    }

    border: 1px solid $border-color;
  }

  .color:not(.active) {
    border: none;
  }

  > a.active,
  > a:not(.size-guide):hover {
    border: 1px solid;
    box-shadow: inset 0 0 0 2px #fff;
  }
}

.brand-carousel {
  img {
    width: 100% !important;
    height: 100%;
    max-width: 300px;
  }

  figure {
    padding: 1rem;
  }
}

// Product Details
.product-details,
.product-hide-details {
  .product-action {
    position: relative;
    opacity: 1;

    .btn-wishlist {
      position: static;
    }
  }

  .btn-cart {
    display: flex;
    align-items: center;
    max-width: 14rem;
    line-height: 2.9;
    padding: 0 0.6em;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #222;
    background-color: transparent;
    border: 2px solid #ccc;
    margin-right: 5px;
    border-radius: 0.3rem;

    i {
      margin-right: 0.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 18px;
      width: 18px;
    }
  }

  .btn-cart {
    flex: 1;
    height: 4.2rem;
    font-size: 1.3rem;
    transition: color 0.3s, background-color 0.3s, border-color 0.3s;
    z-index: 1;
  }

  .btn-quickview {
    margin: 0 0 0 1.6rem;
  }

  .btn-product-icon {
    width: auto;
    border: 0;
    background-color: transparent;
    color: #222;
    transition: color 0.3s, background-color 0.3s, visibility 0.3s;

    i {
      font-size: 1.8rem;
      height: 20px;
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      color: $primary-color;
    }
  }
}

.product-media .btn-product-icon.btn-cart i {
  margin-top: 2px;
  margin-right: 1px;
}

.product:hover {
  .product-action .btn-cart {
    color: #fff;
    background-color: #444;
    border-color: #444;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .product-hide-details,
  .product-details {
    .btn-cart {
      flex: 0 0 12.3rem;
    }
  }

  @include mq(sm, max) {
    .product-hide-details,
    .product-details {
      .btn-cart {
        flex: 0 0 9.5rem;
      }
    }
  }
}

// Product Classic
.product-classic {
  .btn-quickview {
    opacity: 1;

    i:before {
      content: "\e98c";
    }
  }

  .btn-wishlist {
    margin: 0 0 0 1.6rem;
  }

  .btn-wishlist,
  .btn-quickview {
    transform: translateX(-200%);
    opacity: 0;
    visibility: hidden;
    transition: transform 0.3s, opacity 0.3s, visibility 0.3s, color 0.3s,
      background-color 0.3s, border-color 0.3s;
  }

  .btn-quickview {
    transition: transform 0.5s, opacity 0.5s, visibility 0.5s, color 0.3s,
      background-color 0.3s, border-color 0.3s;
  }

  &:hover {
    .btn-wishlist,
    .btn-quickview {
      transform: translateX(0);
      opacity: 1;
      visibility: visible;
    }
  }

  .product-details {
    padding-left: 0;
  }

  .ratings-container {
    margin-bottom: 1.5rem;
  }
}

// Slideup Content
.product-slideup-content {
  overflow: hidden;

  .product-hide-details {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    transition: transform 0.3s;
    background-color: #fff;
  }

  .product-details {
    padding-bottom: 1px;
    background-color: #fff;
    transition: transform 0.3s;
  }

  .btn-wishlist,
  .btn-quickview {
    opacity: 0;
    visibility: hidden;
    transition: transform 0.4s, opacity 0.3s, visibility 0.3s, color 0.3s,
      background-color 0.3s;
  }

  .btn-quickview {
    transform: translateX(-200%);
  }

  .btn-wishlist {
    transform: translateX(200%);
  }

  &:hover {
    .btn-wishlist,
    .btn-quickview {
      transform: translateX(0);
      opacity: 1;
      visibility: visible;
    }
  }
}

// SlideUp
.product-slideup {
  .product-details {
    padding-bottom: 0;
    background-color: #fff;
    transition: transform 0.3s;
  }

  .ratings-container {
    margin-bottom: 0;
  }

  .btn-wishlist,
  .btn-quickview {
    opacity: 0;
    visibility: hidden;
    transition: transform 0.4s, opacity 0.3s, visibility 0.3s, color 0.3s,
      background-color 0.3s;
  }

  .btn-quickview {
    transform: translateX(-200%);
  }

  .btn-wishlist {
    transform: translateX(200%);
  }

  .product-action {
    display: flex;
    position: absolute;
    padding-top: 1.5rem;
    top: 100%;
    bottom: auto;
    visibility: hidden;
    opacity: 0;
  }

  &:hover {
    transform: translateY(-58px);

    .btn-wishlist,
    .btn-quickview {
      transform: translateX(0);
      opacity: 1;
      visibility: visible;
    }

    .product-action {
      visibility: visible;
      opacity: 1;
    }
  }

  img {
    filter: brightness(1.1);
  }
}

.product-slideup,
.product-slideup-content {
  .btn-cart {
    margin-right: 0;
  }

  .btn-wishlist {
    position: static;
    margin: 0 1.6rem 0 0;
  }
}

//Split Line
.split-line {
  overflow: hidden;
}

// Gallery
.product-image-gap {
  padding: 0.9rem;
  border: 1px solid $border-color;
  background-color: #fff;

  .product-details {
    padding: 1.8rem 1rem 1rem;
  }

  .ratings-container {
    margin-bottom: 0;
  }

  &:hover {
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.05);
  }
}

@include mq(xs, max) {
  .product-image-gap .product-details {
    padding: 1.8rem 0 1rem;
  }
}

@include mq(lg) {
  .product {
    &:hover {
      .product-action,
      .product-action-vertical {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

// List
.product-list {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  .product-media {
    flex: 0 0 28rem;
    max-width: 28rem;
    margin: 0 2rem 0 0;
  }

  .product-details {
    padding: 0;
    flex: 1;
  }

  .product-label {
    font-size: 1.1rem;
  }

  .btn-quickview {
    opacity: 1;
  }

  .btn-wishlist {
    margin: 0 0 0 0.8rem;
  }

  .btn-wishlist,
  .btn-quickview {
    transform: translateX(-200%);
    opacity: 0;
    visibility: hidden;
    transition: transform 0.3s, opacity 0.3s, visibility 0.3s, color 0.3s,
      background-color 0.3s;
  }

  .btn-quickview {
    transition: transform 0.5s, opacity 0.5s, visibility 0.5s, color 0.3s,
      background-color 0.3s;
  }

  .ratings-container {
    margin-bottom: 0.8rem;
  }

  .product-name {
    font: {
      size: 1.8rem;
      weight: 600;
    }

    letter-spacing: 0.025em;
    color: $dark-color;
  }

  .product-price {
    font: {
      size: 1.8rem;
      weight: 600;
    }
  }

  .product-short-desc {
    @include text-block(3);
    margin-bottom: 2rem;
    text-transform: none;
    font-size: 1.4rem;
    line-height: 1.78;
    color: inherit;
  }

  &:hover {
    .btn-quickview,
    .btn-wishlist {
      transform: translateX(0);
      opacity: 1;
      visibility: visible;
    }
  }
}

// List
.product-list-sm {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  .product-media {
    flex: 0 0 get(product, list-sm, _image-width);
    @include css(max-width, product, list-sm, _image-width);
    margin: 0 1rem 0 0;
  }

  .product-details {
    padding: 0;
    overflow: hidden;
  }

  .product-name {
    @include text-block();
    margin-bottom: 0.7rem;
    white-space: normal;
    word-break: break-all;
    @include print_css(product, list-sm, name);

    &:hover {
      color: $primary-color;
    }
  }

  .ratings-container {
    margin: 0;
  }

  .product-price {
    font-size: 1.4rem;
  }

  & + .product-list {
    margin-top: 2rem;
  }

  .btn-cart {
    margin-top: 2rem;
    height: 3.3rem;
    max-width: 11.7rem;
    border: 1px solid #d7d7d7;
    color: $secondary-color;
    background: transparent;
    font-size: 1.2rem;

    &:hover {
      border-color: $secondary-color;
      background: $secondary-color;
    }
  }
}

@media (max-width: 1220px) and (min-width: 1200px) {
  .product-tabs .product-tab-description .divider {
    margin-left: -5px;
  }
}

// Other Styles
.product {
  &.text-center {
    .product-cat {
      padding: 0;

      .product-tag {
        font-size: 1.2rem;
        text-overflow: ellipsis;
        color: $success-color;
        text-align: center !important;
        -webkit-line-clamp: 1 !important;
        -webkit-box-orient: vertical !important;
        overflow: hidden !important;

        span {
          visibility: hidden;
        }
      }
    }

    .product-name {
      padding-left: 2rem;
    }

    .product-coupon {
      color: #17b31b;
      font-size: 13px;
      margin-top: 4px;
      font-weight: bolder;
    }

    .product-price,
    .product-variations,
    .ratings-container,
    .product-action {
      justify-content: center;
    }
  }

  &.shadow-media:hover {
    .product-media {
      box-shadow: 0px 20px 20px -16px rgba(0, 0, 0, 0.5);
    }
  }

  &.cart-full,
  &.product-with-qty .product-details {
    .product-price {
      margin-top: 4px;
      margin-bottom: 1rem;
    }

    .btn-cart {
      padding: 0;
      border-color: #ccc;
      transition: background-color 0.3s, border-color 0.3s, color 0.3s;

      &:hover,
      &:focus,
      &:active {
        background-color: $primary-color;
        border-color: $primary-color;
        color: $white-color;
      }
    }
  }

  &.cart-full .btn-cart {
    max-width: none;
    width: 100%;
  }

  &.product-with-qty {
    .product-details {
      padding-left: 0;
      padding-right: 0;
    }

    .btn-cart {
      max-width: 12.3rem;
      margin-right: 0;

      i {
        display: none;
      }
    }

    .product-quantity {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 2rem;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      color: #333;
      font-size: 1rem;
      width: 24px;
      height: 24px;
      border-radius: 0.3rem;
      border: none;
      background: #f4f4f4;
      cursor: pointer;
      transition: background-color 0.3s, color 0.3s;

      &:hover {
        background-color: $primary-color;
        color: #fff;
      }
    }

    .quantity {
      min-height: auto;
      width: 3.2rem;
      padding: 0;
      font-weight: 700;
      font-size: 1.2rem;
      border: none;
      text-align: center;
      color: #222;
    }
  }

  &.product-variable:not(.cart-full) .btn-cart::after {
    display: none;
    font-family: "riode";
    content: "\e97f";
    font-size: 1.8rem;
    font-weight: 400;
  }

  &:hover {
    &.product-with-qty .btn-cart {
      background-color: transparent;
      color: $dark-color;
    }
  }
}

@include mq(sm, max) {
  .product-list {
    display: block;

    .product-media {
      margin: 0;
      max-width: 100%;
    }

    .product-details {
      padding: 1.8rem 0 2rem;
    }
  }

  .product-action-vertical {
    top: 10px;
    right: 10px;
  }
}

@include mq(xs, max) {
  .product-details,
  .product-hide-details {
    .btn-cart {
      max-width: 4.2rem;
      display: flex;

      span {
        display: none;
      }

      i {
        margin-right: 0;
      }
    }
  }

  .product.product-with-qty {
    .product-details .btn-cart {
      max-width: 4.2rem;
      padding: 0.78em 0;
    }

    .btn-cart i {
      display: block;
    }

    .product-quantity {
      margin-right: 1rem;
    }
  }

  .cart-full .product-details .btn-cart span {
    display: inline;
  }

  .cart-full .btn-cart i {
    display: none;
  }

  // .product-label-group {
  //     left: 1.2rem;
  //     top: 0rem;
  // }

  .btn-quickview {
    font-size: 1.2rem;
  }

  .btn-sold-out {
    font-size: 1.1rem;
  }

  .btn-product-icon {
    width: 3.2rem;
    height: 3.2rem;
    font-size: 1.3rem;
  }

  .product.product-variable:not(.cart-full) .btn-cart::after {
    display: block;
  }

  .product.product-variable:not(.cart-full) .btn-cart i {
    display: none;
  }
}

// Product Filters
.product-filters {
  margin-right: 1.5rem;
}

.product-details {
  .product-divider {
    margin-top: 0;
  }

  .product-form.product-qty,
  .product-variation-price {
    padding-bottom: 7px;
  }
}

@include mq(sm, max) {
  .header {
    width: 100%;
    // position: fixed;
    top: 0;
    z-index: 99;
  }

  .product-details {
    .cart-button-wrapper {
      width: 100vw;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      bottom: -1px;
      left: 0;
      background: #fff;
      z-index: 99;
      padding-top: 10px;
      padding-bottom: 10px;
      box-shadow: 0 0 10px 1px rgb(0 0 0 / 10%);
      font-weight: bolder;

      .btn-cart {
        margin: 12px;
      }

      .btn-cart-width {
        max-width: 100%;
      }
    }
    .cart-button-wrapper.wrapper-btn {
      width: 95.5vw;
    }
  }
}

.tag-wrapper {
  white-space: nowrap;
  font-weight: 400 !important;
  width: fit-content;
  color: #fff !important;

  padding: 0rem 1rem;
  text-align: center;
  border-radius: 0.3rem;
  font-size: 1.4rem;

  &.cancel {
    background-color: $secondary-color;
  }

  &.success {
    background-color: $success-color;
  }

  &.info {
    background-color: $info-color;
  }
}

@include mq(xs, max) {
  .product {
    .product-sm {
      .old-price {
        font-size: 1.3rem;
      }

      .new-price {
        span {
          font-size: 1.3rem !important;
        }

        font-size: 1.6rem;
      }
    }
  }
}
