/* -------------------------------------------
    No UiSlider
---------------------------------------------- */

.noUi-target {
    background: $border-color-light;
    margin: 4px 16px 3rem 9px;
}
.noUi-connects {
    padding: 11.5px 0;
    top: -10px;
}
.noUi-connect {
    background: #999;
    margin-top: 11px;
    height: 3px;
}
.noUi-horizontal .noUi-handle {
    top: -0.6rem;
    width: 1.2rem;
    height: 1.7rem;
    background: #444;
    border-radius: 3px;
}