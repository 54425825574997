/* -------------------------------------------
    FAQs Page
---------------------------------------------- */

.faq {
  .faq-title {
    text-align: center; /* Center the text within the title */
    margin-bottom: 3rem;
    font-size: 36px;
    color: black;
    font-weight: 600;

    @media (max-width: 767px) {
      font-size: 28px;
    }

    @media (max-width: 500px) {
      font-size: 20px;
    }
  }
  .breadcrumb {
    padding: 1.2rem 0 1.1rem;
  }

  .page-header {
    height: 30.3rem;
  }

  .page-subtitle {
    margin-bottom: 0.3rem;
    font-size: 3rem;
  }

  .page-title {
    margin-bottom: 0.2rem;
    font-size: 5rem;
  }

  section {
    padding-top: 4.1rem;
  }

  .card {
    border-width: 1px 0 0 0;
    border-color: #ebebeb;

    &:last-child {
      border-color: #ebebeb;
    }
  }

  .card-header {
    font-size: 1.6rem;
    text-transform: none;
    letter-spacing: 0.025em;

    p {
      font-size: 16px !important;
      font-weight: 600 !important;
      margin-bottom: 0 !important;
      font-family: Arial, Helvetica, sans-serif;
    }
    span {
      color: #000 !important;
      background-color: transparent !important;
      font-family: Arial, Helvetica, sans-serif;
    }
    a {
      padding: 1.7rem 1rem;

      &::after {
        font-size: 1.5rem;
        right: 1.3rem;
        color: $grey-color;
      }
    }
  }

  .card-body {
    padding: 0.2rem 1rem;

    p {
      font-size: 14px;
      font-weight: 500;
      font-family: Arial, Helvetica, sans-serif;
    }
    span {
      color: #000 !important;
      background-color: transparent !important;
      font-family: Arial, Helvetica, sans-serif;
    }
  }

  .faq-accordion {
    border-radius: 50%;
    width: 60vw; /* Adjust the width of the accordions */
    /* Add any additional styles for the accordions */
    @media (max-width: 767px) {
      width: 90vw;
    }
  }
}
