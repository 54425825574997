/* -------------------------------------------
    Counter
---------------------------------------------- */
.count-to {
    display: inline-block;
    margin-bottom: 1.6rem;
    font-size: 5rem;
    font-weight: 700;
    letter-spacing: .064em;
    line-height: 1;

    &::after {
        content: '+';
    }
}

.count-title {
    margin-bottom: .7rem;
    font-size: 2rem;
}