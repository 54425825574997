/* -------------------------------------------
        Base
    ---------------------------------------------- */

// Variables
@include set-default(
  (
    base: (
      // max-width of '.container'
      _container-width: 1220px,
      // max-width of '.container-fluid'
      _container-fluid-width: 1820px,
      // grid spaces
      _gutter-lg: 15px,
      _gutter-md: 10px,
      _gutter-sm: 5px,
      _gutter-xs: 1px,
      // background of grey section
      _grey-section-bg: #f6f7f9,
      // Body
      body:
        (
          font-family: $font-family,
          font-size: 1.4rem,
          line-height: 1.6,
          color: $body-color
        ),
      page-wrapper: (margin-left: false, margin-right: false),
      // ScrollTop
      scroll-top: (background-color: #fff),
    ),
  )
);

*,
::after,
::before {
  touch-action: pan-x pan-y !important;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  font-size-adjust: 100%;
  font-weight: 400;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  margin: 0;
  // overflow-x: hidden;
  @include print_css(base, body);
  overflow-x: hidden !important;
  overflow-y: scroll !important;
  touch-action: pan-x pan-y;
  letter-spacing: 0.05rem !important;
}

main {
  display: block;
  position: relative;
}

body:not(.loaded) * {
  transition: all 0s !important;
}

.spin-loader {
  width: 17px;
  height: 17px;
  -webkit-animation: spin 650ms infinite linear;
  animation: spin 650ms infinite linear;
  border-radius: 75%;
  border: 2px solid #fff !important;
  border-bottom-color: transparent !important;
  z-index: 9999;
}

.container1 {
  max-width: 1250px;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loaded .page-wrapper {
  opacity: 1;
}

.App {
  text-align: center;
  margin-top: 50px;
}

.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
}

.page-wrapper {
  opacity: 0;
  position: relative;
  transition: margin 0.4s, opacity 0.5s;
  @include print_css(base, page-wrapper);
  min-height: 100vh;
}

.page-wrapper.checkout {
  background-color: #f3f7ef;
}

.link-text {
  color: cadetblue;
}

.page-content {
  color: $text-color-1;
}

table {
  width: 100%;
  border-collapse: collapse;

  td,
  th {
    padding: 0;
  }
}

.section {
  padding: 7rem 0;
}

.grey-section {
  @include css(background, base, _grey-section-bg);
}

.background-section {
  background-repeat: no-repeat;
}

.parallax {
  background-color: $background-color-2;
}

ul {
  padding-left: 1.5em;
}

.menu,
.menu ul,
.mobile-menu,
.mobile-menu ul,
.nav,
.nav ul,
.widget-body,
.widget-body ul,
.list,
.breadcrumb,
.filter-items,
.select-menu > ul,
.dropdown-box,
.pagination,
.nav-filters,
.category ul,
.comments ul,
.product-nav,
.product-tabs > div ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
  transition: color 0.3s;
  // &:hover {
  //     opacity: 70%;
  // }
}

.mobile-menu {
  a {
    &:hover {
      opacity: 70%;
    }
  }
}

:focus {
  outline: 0;
}

figure {
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
}

hr {
  margin-bottom: 2rem;
  border: 0;
  border-top: 1px solid $border-color-light;
}

input {
  -webkit-appearance: none;
  font-size: 16px !important;
  border: 0;
  padding: 0;
  font-family: $font-family;

  &:focus {
    outline: 0;
  }
}

i {
  font-style: normal;
}

button {
  &:focus {
    outline: none;
  }
}

.scrollable,
.sidebar-content {
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    height: 7px;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    margin-right: 2px;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
  }
}

.scrollable-light::-webkit-scrollbar-thumb {
  background: rgba(#fff, 0.2);
}

// Overlay Animation
@keyframes bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(1);
  }

  40% {
    transform: scale(1.2);
  }
}

@keyframes rotatedelay {
  0% {
    transform: rotateZ(0);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes reveal-1 {
  0% {
    transform: rotate3d(0, 0, 1, 135deg) translate3d(0, 0, 0);
    border-radius: 50%;
  }

  20% {
    transform: rotate3d(0, 0, 1, 135deg) translate3d(55%, 55%, 0);
    border-radius: 0;
  }

  80% {
    transform: rotate3d(0, 0, 1, 495deg) translate3d(55%, 55%, 0);
    border-radius: 0;
  }

  100% {
    transform: rotate3d(0, 0, 1, 495deg) translate3d(0, 0, 0);
    border-radius: 50%;
  }
}

@keyframes reveal-2 {
  0% {
    transform: rotate3d(0, 0, 1, 135deg) translate3d(0, 0, 0);
    border-radius: 50%;
  }

  20% {
    transform: rotate3d(0, 0, 1, 135deg) translate3d(55%, -55%, 0);
    border-radius: 0;
  }

  80% {
    transform: rotate3d(0, 0, 1, 495deg) translate3d(55%, -55%, 0);
    border-radius: 0;
  }

  100% {
    transform: rotate3d(0, 0, 1, 495deg) translate3d(0, 0, 0);
    border-radius: 50%;
  }
}

@keyframes reveal-3 {
  0% {
    transform: rotate3d(0, 0, 1, 135deg) translate3d(0, 0, 0);
    border-radius: 50%;
  }

  20% {
    transform: rotate3d(0, 0, 1, 135deg) translate3d(-55%, -55%, 0);
    border-radius: 0;
  }

  80% {
    transform: rotate3d(0, 0, 1, 495deg) translate3d(-55%, -55%, 0);
    border-radius: 0;
  }

  100% {
    transform: rotate3d(0, 0, 1, 495deg) translate3d(0, 0, 0);
    border-radius: 50%;
  }
}

@keyframes reveal-4 {
  0% {
    transform: rotate3d(0, 0, 1, 135deg) translate3d(0, 0, 0);
    border-radius: 50%;
  }

  20% {
    transform: rotate3d(0, 0, 1, 135deg) translate3d(-55%, 55%, 0);
    border-radius: 0;
  }

  80% {
    transform: rotate3d(0, 0, 1, 495deg) translate3d(-55%, 55%, 0);
    border-radius: 0;
  }

  100% {
    transform: rotate3d(0, 0, 1, 495deg) translate3d(0, 0, 0);
    border-radius: 50%;
  }
}

.bounce-loader {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  //margin: -9px 0 0 -35px;
  transition: all 0.2s;
  text-align: center;
  z-index: 10000;

  //animation: 2s ease-in-out 0s normal both infinite rotatedelay;
  .bounce1,
  .bounce2,
  .bounce3,
  .bounce4 {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    margin-bottom: 5px;
    // border-radius: 100%;
    background-color: $primary-color;
    //box-shadow: 0 0 20px 0 rgba(0, 0, 0, .15);
    //animation: 1s ease-in-out 0s normal both infinite bouncedelay;
  }

  .bounce1 {
    animation: 2s infinite reveal-1;
  }

  .bounce2 {
    animation: 2s infinite reveal-2;
  }

  .bounce3 {
    animation: 2s infinite reveal-3;
  }

  .bounce4 {
    animation: 2s infinite reveal-4;
  }

  .bounce3 {
    border: 3px solid $primary-color;
    background-color: transparent;
  }
}

// Animation
.appear-animate {
  transform: translate3d(0, 0, 0) scale(1);
  will-change: transform, filter, opacity;
}

.fade {
  opacity: 0;
  transition: opacity 0.5s;

  &.in {
    opacity: 1;
  }
}

// ScrollTop
.scroll-top {
  position: fixed;
  text-align: center;
  bottom: 30px;
  left: auto;
  right: 30px;
  width: 60px;
  height: 60px;
  font-size: 27px;
  opacity: 0;
  visibility: hidden;
  transition: transform 0.3s, visibility 0.3s, opacity 0.3s;
  color: #222;
  transform: translateY(40px);
  border-radius: 3px;
  z-index: 999;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  line-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include print_css(base, scroll-top);

  &:hover {
    color: #222;
  }

  i {
    font-weight: 900;
    line-height: inherit;
  }
}

// d-loading
.d-loading {
  display: block;
  position: absolute;
  top: 100%;
  left: calc(50% - 17px);
  width: 34px;
  height: 34px;
  border: 2px solid transparent;
  border-top-color: rgb(0, 0, 0);
  border-radius: 50%;
  animation: spin 0.75s infinite linear;

  &:before {
    content: "";
    top: -2px;
    left: -2px;
    position: absolute;
    width: inherit;
    height: inherit;
    border: inherit;
    border-radius: inherit;
    animation: spin 1.5s infinite ease;
  }
}

// toaster
.Toastify {
  .Toastify__toast-body {
    padding: 0 !important;
  }

  .Toastify__toast {
    min-height: fit-content !important;
  }
}

// body {
//     transition: opacity .1s;
//     opacity: 0;

//     &.loaded {
//         opacity: 1;
//     }
// }

@include mq(sm, max) {
  main {
    // margin-top: 60px;

    // &.searchBar {
    //   margin-top: 130px !important;
    // }
  }
}

@include mq(lg, max) {
  #__next {
    overflow: hidden;
  }
}

@include mq(md) {
  .scroll-top.show {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
}

// Sticky Content Animation
@keyframes fixedTop {
  0% {
    margin-top: -60px;
  }

  100% {
    margin-top: 0;
  }
}

@keyframes fixedBottom {
  from {
    transform: translateY(100%);
    transform-origin: center top 0px;
  }

  to {
    transform: translateY(0);
  }
}

// Sticky Content(new)
.sticky-content {
  &.fix-top {
    top: 0;
  }

  &.fix-bottom {
    bottom: 0;
  }

  &.fixed {
    &.fix-top {
      animation: fixedTop 0.4s;
    }

    &.fix-bottom {
      animation: fixedBottom 0.4s;
      transition: margin-bottom 0.4s;
    }

    position: fixed;
    left: 0;
    right: 0;
    opacity: 1;
    // transform: translateY(0);
    background: #fff;
    z-index: 1051;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
  }
}

// Load more icon animation (new)
.loading:not(.load-more-overlay),
.load-more-overlay.loading::after {
  animation: spin 650ms infinite linear;
  border: 2px solid transparent;
  border-radius: 32px;
  border-top: 2px solid rgba(0, 0, 0, 0.4) !important;
  border-right: 2px solid rgba(0, 0, 0, 0.4) !important;
  border-bottom: 2px solid rgba(0, 0, 0, 0.4) !important;
  content: "";
  display: block;
  height: 21px;
  top: 50%;
  margin-top: -11px;
  left: 50%;
  margin-left: -10px;
  right: auto;
  position: absolute;
  width: 21px;
}

.load-more-overlay {
  position: relative;

  &.loading::after {
    content: "";
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #fff;
    opacity: 0.8;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

.riode-rounded-skin {
  .btn,
  .post-calendar,
  .product-hide-details .btn-product,
  .product-hide-details .btn-product-icon,
  .post-single > .post-wrap img,
  .post-single .post-author-detail,
  .post-media,
  .post-framed,
  .minipopup-box,
  .product-category,
  .product-category .category-content,
  .product-with-qty .quantity button,
  .product-wrapper .banner,
  .vendor-widget .vendor-logo,
  .vendor-widget .vendor-product > figure,
  .vendor-widget .vendor-banner {
    border-radius: 3px;
    overflow: hidden;
  }

  .login-popup {
    border-radius: 3px;
  }

  .form-row [type="text"],
  .form-row [type="tel"],
  .form-row [type="password"],
  .form-row [type="email"],
  .form-row textarea {
    border-radius: 3px;
  }

  .btn-link,
  .form-coupon .input-text {
    border-radius: 0;
    overflow: visible;
  }

  .post.post-mask.gradient::before {
    border-radius: 0 0 3px 3px;
    overflow: hidden;
  }

  .mfp-product .product,
  .product-category.cat-type-default {
    border-radius: 10px;
  }
}

.overflow-ellipsis {
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
}

.overflow-ellipsis2 {
  -webkit-box-orient: vertical;
  overflow: hidden;
}

//border
.border-regular {
  border: 1px solid #e1e1e1;
}

.border-cricle {
  border: 1px solid #e1e1e1;
  border-radius: 50%;
}

.text-align-start {
  text-align: start !important;
}

.cart-product-size {
  font-size: 14px !important;
  font-weight: bolder !important;

  @media (max-width: 500px) {
    font-size: 12px !important;
  }
}

.top-spacing {
  padding-top: 15px !important;
}

.button-12 {
  background-color: #4c9764 !important;
  background-image: linear-gradient(#7bb806, #279b35);
  border-radius: 20px !important;
  font-size: 20px;
  font-weight: 600;
  line-height: 120% !important;
}

.w-button {
  display: inline-block;
  padding: 9px 15px;
  background-color: #3898ec;
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0;
}

.wbs-button-primary {
  background-color: #fec84b !important;
  color: #fff;
  text-align: center;
  background-image: linear-gradient(#bd9a43, #bd9a43) !important;

  // background-image: linear-gradient(#7bb806, #279b35) !important;
  border-radius: 6px !important;
  padding: 13px 30px;
  font-size: 18px;
  font-weight: 700;
  transition: background-color 0.3s ease-in-out;
}

.buy-btn {
  @media (max-width: 991px) {
    width: 100%;
  }
}
.buy-btn.wrap {
  @media (min-width: 992px) {
    font-size: 14px;
    padding: 13px 20px;
  }
}

.button-w {
  width: 80%;
}

.description-page-padding {
  background-color: #f3f7ef;
  background-image: url(../../../public/images/intro-back.png);
  background-position: 50% 10%;
  background-repeat: no-repeat;
  background-size: 50%;
  background-attachment: scroll;
  padding-left: 2rem;
  padding-right: 2rem;

  @media screen and (max-width: 767px) {
    background-position: 50% 100%;
    background-size: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  @media screen and (max-width: 991px) {
    background-position: 50% 130%;
    background-size: 70%;
    background-attachment: scroll;
  }
}

.description-page-padding.wrap {
  background-image: url(../../../public/images/bg-acne.png);
  background-position: 50% 100%;
}

.description-aloe-page-padding {
  background-color: #f3f7ef;
  background-image: url(../../../public/images/background-aloe-vera.png);
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: 50%;
  background-attachment: scroll;
  padding-left: 2rem;
  padding-right: 2rem;

  @media screen and (max-width: 767px) {
    background-position: 50% 100%;
    background-size: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  @media screen and (max-width: 991px) {
    background-position: 50% 130%;
    background-size: 70%;
    background-attachment: scroll;
  }
}

.descrition-heading {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.description-max-width {
  width: 100%;
  text-align: center;

  @media (max-width: 767px) {
    padding-top: 20px;
  }
}

.description-heading-text {
  color: #101828;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Montserrat, sans-serif;
  font-size: 30px;
  font-weight: 600;
  line-height: 45px;
  text-align: center;

  @media (max-width: 767px) {
    font-size: 24px;
  }
}

.footer-gap {
  gap: 2rem;
}

.description-text {
  color: #475467;
  font-family: Montserrat, sans-serif;
  font-size: 2rem;
  line-height: 1.5;
  margin-bottom: 3rem;

  @media (max-width: 767px) {
    font-size: 18px;
  }
}

.max-width-desc {
  width: 100%;
  max-width: 76.8rem;
  margin-left: auto;
  margin-right: auto;
}

.btn-large {
  grid-column-gap: 1.2rem;
  padding: 1.6rem 0.8rem;
  font-size: 1.8rem;
}

.uui-button {
  grid-column-gap: 0.5rem;
  border: 1px solid #7f56d9;
  background-color: #7f56d9;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 1.125rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-decoration: none;
  transition: all 0.3s;
  display: flex;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.button-primary-1 {
  background-color: #4d5865;
  color: #fff;
  text-align: center;
  background-image: linear-gradient(#bd9a43, #bd9a43);
  border-radius: 6px;
  padding: 13px 30px;
  font-size: 15px;
  font-weight: 700;
  transition: background-color 0.3s ease-in-out;

  @media screen and (max-width: 479px) {
    max-width: 80%;
    white-space: normal;
    flex-wrap: nowrap;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }
}

.desc-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.image-wrapper {
  margin-top: 4rem;

  @media (max-width: 767px) {
    margin-top: 0rem;
  }
}

.brands-container {
  background-color: #fff;
}

.brand-title {
  display: flex;
  justify-content: center;
  color: #d88c00;
  text-decoration: underline;
  font-size: 24px;
}

.trivia-box {
  width: 80%;
  text-align: center;
  background-color: #e5edf0;
  margin: 60px auto;
  padding: 20px;
}

.text-block {
  font-size: 14px;
  text-align: center;
}
.text-block.wrap {
  font-size: 18px;
  text-align: center;

  @media (max-width: 767px) {
    font-size: 14px;
  }
}

.rec-section {
  max-width: 100%;
  min-height: 200px;
  background-color: #fff;
  padding-top: 0;
  padding-bottom: 0;
  font-family: Montserrat, sans-serif;
  display: block;
}

.rec-section.new {
  max-width: 100%;
  min-height: 200px;
  background-color: #fff;
  padding-top: 0;
  padding-bottom: 0;
  font-family: Montserrat, sans-serif;
  display: block;
  padding: 80px 30px;

  @media (max-width: 767px) {
    padding: 50px 15px;
  }

  img {
    width: 270px;

    @media (max-width: 991px) {
      width: 190px;
    }
  }
}

.container-55 {
  width: 100%;
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
}

.better-you {
  color: #c99034;
  text-align: center;
  margin-top: 0;
  margin-bottom: 40px;
}

.gp-container.advantages {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  padding: 0 10px 60px;
  display: grid;

  @media screen and (max-width: 767px) {
    grid-template-rows: auto auto auto;
    grid-template-columns: 1.25fr;
    padding-bottom: 0 20px 60px;
  }

  @media screen and (max-width: 479px) {
    padding: 0 20px 60px;
  }
}

.gp-container {
  max-width: 1200px;
  min-height: 50px;
  background-color: rgba(218, 41, 1, 0);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;

  @media screen and (max-width: 479px) {
    object-fit: fill;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
  @media screen and (max-width: 991px) {
    max-width: 95%;
  }
}

.w-container-1 {
  margin-left: auto;
  margin-right: auto;
  max-width: 1140px;
}

.w-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;

  .w-row {
    margin-left: -10px;
    margin-right: -10px;

    @media screen and (max-width: 767px) {
      margin-left: 0;
      margin-right: 0;
    }
  }
  .w-col-4 {
    width: 33.33333333%;
  }

  .w-col {
    position: relative;
    float: left;
    width: 100%;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;

    @media screen and (max-width: 479px) {
      width: 100% !important;
    }

    @media screen and (max-width: 767px) {
      width: 100% !important;
      left: auto;
      right: auto;
    }
  }
  .w-inline-block {
    max-width: 100%;
    display: inline-block;
  }

  .w-col-4 {
    width: 33.33333%;
  }

  .w-col-28 {
    width: 28%;
  }

  .w-col-8 {
    width: 66.66666666%;
  }

  .w-col-6 {
    width: 50%;
  }
}
.w-container:before,
.w-container:after {
  content: " ";
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2;
}

.w-container:after {
  clear: both;
}

.w-row:before,
.w-row:after {
  content: " ";
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2;
}
.w-row:after {
  clear: both;
}

.product-list-content {
  width: 1200px;
  margin-top: 100px !important;

  @media screen and (max-width: 479px) {
    margin-top: 40px !important;
  }

  @media screen and (max-width: 767px) {
    margin-top: 20px !important;
  }
  @media screen and (max-width: 991px) {
    width: auto;
  }

  .heading {
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    font-size: 24px;
    line-height: 150%;
    margin-top: 20px;

    @media screen and (max-width: 767px) {
      font-size: 22px;
    }
  }
}

.advantage {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;

  .advantage-1 {
    grid-area: 1/2/2/3 !important;
  }
}

.reason-img {
  max-width: 80% !important;
  border: 1px #ececec;
  border-radius: 10px;
  margin-left: auto;
  display: block;
  min-width: 80% !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;

  @media screen and (max-width: 991px) {
    max-width: 90%;
  }
}

.padding-extra {
  padding-left: 100px;
  width: 40%;

  @media screen and (max-width: 991px) {
    padding-left: 0;
  }
}

.reasons-top {
  margin-top: 100px;

  @media (max-width: 767px) {
    margin-top: 10px;
  }
}

.d-header {
  display: contents;
}

.button-hair-issues {
  max-width: 60%;
  text-align: center;
  background-color: #ff6914;
  border-radius: 14px;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto;
  padding: 20px 10px;
  font-size: 30px;
  font-weight: 700;
  line-height: 30px;
  display: block;
  color: #fff;

  @media screen and (max-width: 479px) {
    max-width: 95%;
    font-size: 20px;
  }

  @media screen and (max-width: 767px) {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 20px;
  }
}

.text-recommended {
  text-align: center;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 25px;
}

.container-2 {
  text-align: center;
  background-color: #f7ffef;
  margin-top: 60px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;

  @media (max-width: 767px) {
    padding-top: 0;
    padding-bottom: 20px;
    margin-top: 10px;
  }
}

.testimonial-component {
  column-count: 3;
  column-gap: 3.2rem;
  margin-top: 6.4rem;
  padding-left: 3.2rem;
  padding-right: 3.2rem;

  @media screen and (max-width: 991px) {
    column-count: 2;
    padding-left: 0;
    padding-right: 0;
  }
  @media screen and (max-width: 767px) {
    column-count: 1;
    margin-top: 4.8rem;
    padding-left: 0;
    padding-right: 0;
  }

  .testimonial-content {
    width: 100%;
    border: 1px solid #f9fafb;
    background-color: #f9fafb;
    border-radius: 1.6rem;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 3.2rem;
    padding: 3.2rem;
    display: inline-block;
    min-height: 398px;

    @media screen and (max-width: 1225px) {
      min-height: 427px;
    }

    @media screen and (max-width: 767px) {
      margin-bottom: 2.4rem;
      padding: 2.4rem;
      min-height: auto;
    }
  }

  .rating-wrapper {
    align-items: center;
    margin-bottom: 2.4rem;
    display: flex;
  }

  .uui-heading-xxsmall {
    color: #101828;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.25;
    text-align: left;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
      Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans,
      Droid Sans, sans-serif;

    @media screen and (max-width: 767px) {
      font-size: 1.8rem;
    }
  }

  .testimonial-client {
    text-align: left;
    margin-top: 2.4rem;
    display: flex;

    @media screen and (max-width: 767px) {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .customer-image {
    width: 4.8rem;
    height: 4.8rem;
    min-height: 4.8rem;
    min-width: 4.8rem;
    object-fit: cover;
    border-radius: 100%;
  }

  .client-heading {
    color: #101828;
    letter-spacing: normal;
    font-size: 16px;
    font-weight: 600;
  }
}

.container-large-2 {
  width: 100%;
  max-width: 128rem;
  margin-left: auto;
  margin-right: auto;
}

.rich-text-block {
  font-size: 15px;
  line-height: 1.5;
}

.button-product-ingredients {
  max-width: 410px;
  text-align: center;
  background-color: #ff6914;
  border-radius: 14px;
  margin: 40px auto;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.5;
  display: block;

  @media screen and (max-width: 479px) {
    max-width: 95%;
    font-size: 20px;
  }

  @media screen and (max-width: 767px) {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.exclusive-nooffer {
  color: #813c3c;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
}

.heading-routine {
  text-align: center;
  background-color: #ffe8aa;
  margin-top: 60px;
  margin-bottom: 10px;
  padding-top: 100px;
  padding-bottom: 100px;

  @media screen and (max-width: 479px) {
    margin-top: 40px;
  }

  @media screen and (max-width: 767px) {
    margin-top: 20px;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .heading {
    margin-top: 0;
    font-size: 24px;
    font-weight: 400;
    line-height: 140%;

    @media screen and (max-width: 479px) {
      font-size: 18px;
    }

    @media screen and (max-width: 767px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .sub-heading {
    max-width: 1200px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    font-size: 52px;
    font-weight: 600;
    line-height: 120%;

    @media screen and (max-width: 767px) {
      padding-left: 20px;
      padding-right: 20px;
      font-size: 30px;
    }

    @media screen and (max-width: 479px) {
      font-size: 25px !important;
    }
  }
}

.h-heading {
  margin-top: 0;
  font-size: 24px;
  font-weight: 400;
  line-height: 140%;

  @media screen and (max-width: 479px) {
    font-size: 18px;
  }

  @media screen and (max-width: 767px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.h-sub-heading {
  max-width: 1200px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  font-size: 52px;
  font-weight: 600;
  line-height: 120%;

  @media screen and (max-width: 479px) {
    font-size: 25px;
  }

  @media screen and (max-width: 767px) {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 30px;
  }
}

.step-heading {
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
  font-size: 30px;

  @media screen and (max-width: 479px) {
    max-width: none;
    text-align: center;
    padding-left: 0;
    font-size: 20px;
    display: block;
  }
}

.image-routine {
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.routine-p {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.faq-list-wrapper {
  margin-top: 40px;
  margin-bottom: 40px;
  padding-left: 100px;
  padding-right: 100px;

  @media screen and (max-width: 479px) {
    padding-left: 10px;
    padding-right: 10px;
  }

  @media screen and (max-width: 767px) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media screen and (max-width: 991px) {
    margin-top: 20px;
  }

  .w-dyn-empty {
    padding: 10px;
    background-color: #dddddd;
  }
}

.generic-top-desktop {
  width: 1920px;
  max-width: 100%;
  min-height: 200px;
  background-color: #fff;
  padding-top: 60px;
  padding-bottom: 60px;
  display: block;

  @media screen and (max-width: 767px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @media screen and (max-width: 991px) {
    padding-top: 60px;
  }
}

.generic-top-desktop.hero-section {
  width: 100%;
  background-color: #f3f7ef;
  margin-top: 0;
  padding-top: 20px;
  padding-bottom: 40px;
  text-decoration: none;
  display: block;

  @media screen and (max-width: 767px) {
    margin-top: 10px;
    padding-bottom: 0;
    display: inline-block;
  }

  @media screen and (max-width: 991px) {
    padding-top: 0;
  }

  @media screen and (max-width: 479px) {
    width: 100%;
    margin-top: 20px;
    display: block;
  }
}

.pack-title {
  font-size: 16px;
  font-weight: bolder;
  margin-bottom: 0;
}

.old-price {
  font-size: 16px;
  color: #afafaf;
}

.header-slide {
  width: 1200px;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 991px) {
    width: 100%;
  }

  .column {
    padding-top: 0;
    padding-right: 140px;
    font-family: Montserrat, sans-serif;
    display: block;

    @media screen and (max-width: 991px) {
      padding-right: 0;
    }

    @media screen and (max-width: 479px) {
      padding-top: 20px;
    }
  }

  .w-row {
    @media screen and (max-width: 767px) {
      margin-left: 0;
      margin-right: 0;
    }
  }
  .w-col-4 {
    width: 33.33333333%;
  }

  .w-col {
    position: relative;
    float: left;
    width: 100%;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;

    @media screen and (max-width: 479px) {
      width: 100%;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      left: auto;
      right: auto;
    }
  }
  .w-inline-block {
    max-width: 100%;
    display: inline-block;
  }

  .w-col-4 {
    width: 33.33333%;
  }

  .w-col-8 {
    width: 66.66666666%;
  }

  .w-col-6 {
    width: 50%;

    @media screen and (max-width: 479px) {
      width: 100%;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      left: auto;
      right: auto;
    }
  }

  .heading-2 {
    font-size: 30px;
    line-height: 120%;
    font-family: Montserrat, sans-serif;

    @media screen and (max-width: 479px) {
      text-align: center;
      margin-top: 0px;
      margin-bottom: 10px;
      padding-right: 20px;
      font-size: 22px;
      line-height: 30px;
      overflow: visible;
    }

    @media screen and (max-width: 767px) {
      margin-top: 0px;
      margin-bottom: 0;
      padding-left: 20px;
      padding-right: 40px;
      font-size: 26px;
    }
  }

  .paragraph {
    font-size: 16px;
    line-height: 140%;

    @media screen and (max-width: 479px) {
      text-align: center;
      padding-left: 0;
      font-size: 14px;
    }

    @media screen and (max-width: 767px) {
      margin-top: 10px;
      padding-left: 20px;
    }
  }

  .sub-heading {
    color: #bd8337;
    text-decoration: none;

    @media screen and (max-width: 479px) {
      text-align: center;
      padding-left: 0;
    }

    @media screen and (max-width: 767px) {
      padding-left: 20px;
    }
  }
}

.wbs-section {
  padding: 0 5% 0 0%;

  @media screen and (max-width: 767px) {
    padding: 0 2%;
  }

  @media screen and (max-width: 991px) {
    padding-top: 0;
    padding-bottom: 0;
  }

  .wbs-container {
    width: 100%;
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
  }
}

.wbs-section.acv-multipack {
  padding-top: 0.125rem;
  padding-left: 0%;

  @media screen and (max-width: 479px) {
    padding-bottom: 0;
  }

  @media screen and (max-width: 767px) {
    padding-bottom: 0;
  }
}

.wbs-section-2 {
  padding: 1rem 2% 0 0%;

  @media screen and (max-width: 479px) {
    padding-bottom: 0;
  }

  @media screen and (max-width: 767px) {
    padding: 2rem 2%;
  }
  @media screen and (max-width: 991px) {
    padding-top: 1rem;
    padding-bottom: 0;
  }

  .wbs-container {
    width: 100%;
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
  }

  .wbs-feature {
    grid-row-gap: 50px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;

    @media screen and (max-width: 767px) {
      grid-column-gap: 30px;
      grid-row-gap: 30px;
      grid-template-columns: 1fr;
    }
  }

  .wbs-feature-item {
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    justify-content: flex-start;
    align-items: stretch;
    display: flex;

    @media screen and (max-width: 479px) {
      grid-column-gap: 12px;
      grid-row-gap: 12px;
      justify-content: flex-start;
    }

    @media screen and (max-width: 767px) {
      grid-column-gap: 12px;
      grid-row-gap: 12px;
      flex-direction: row;
      grid-template-rows: auto auto;
      grid-template-columns: 1fr 1fr;
      grid-auto-columns: 1fr;
      justify-content: center;
      display: flex;
    }
    @media screen and (max-width: 991px) {
      justify-content: center;
    }
  }
}

.wbs-cta.acv-multipack {
  margin-bottom: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.wbs-cta.best-value {
  border-color: #f0c761;
}
.wbs-cta.rest-packs {
  border-color: #f6ebcd;
  box-shadow: none;
}

.wbs-cta.wrap {
  @media (min-width: 991px) {
    height: 170px;
  }
}

.wbs-cta {
  z-index: 1;
  max-width: 100%;
  grid-column-gap: 14px;
  grid-row-gap: 16px;
  background-color: var(--flowstica-webflow-library-white);
  border: 2px solid #ddd;
  border-radius: 10px;
  grid-template-rows: auto;
  grid-template-columns: 0.5fr 0.5fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 20px;
  font-family: Montserrat, sans-serif;
  display: flex;
  position: relative;

  @media screen and (max-width: 479px) {
    max-width: 100% !important;
    padding: 20px;
  }
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
  @media screen and (max-width: 991px) {
    max-width: 100% !important;
    flex-direction: column;
    align-items: flex-start;
  }
}

.wbs-cta.shila {
  padding: 10px 12px 20px;
  border-radius: 0;
  margin-bottom: 35px;

  @media (min-width: 992px) {
    height: 150px !important;
  }
}

.wbs-cta-img {
  z-index: 10;
  height: 120px;
  position: absolute !important;
  top: 10% !important;
  bottom: 0% !important;
  left: 2% !important;
  right: 0% !important;

  @media screen and (max-width: 991px) {
    height: 150px !important;
    display: block !important;
    top: 10% !important;
    bottom: 0% !important;
    left: auto !important;
    right: 5% !important;
  }
  @media screen and (max-width: 767px) {
    height: 150px !important;
    right: 10% !important;
  }

  @media screen and (max-width: 479px) {
    height: 120px !important;
    right: 10% !important;
  }
}

.wbs-cta-img.wrap {
  transform: none !important;
  @media screen and (min-width: 991px) {
    top: 5% !important;
  }
}

.wbs-cta-img.shila {
  @media (min-width: 992px) {
    height: 120px;
  }
}

.div-block {
  padding-left: 75px;
  @media screen and (max-width: 479px) {
    padding-left: 0;
  }

  @media screen and (max-width: 991px) {
    padding-left: 0;
  }
}

.acv-savings {
  color: #b40000;
  font-weight: 700;
}

.testimonial-slide {
  width: 600px;
  height: auto;
  max-width: 600px;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 767px) {
    width: 80%;
    height: auto;
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (max-width: 991px) {
    width: 100%;
    height: auto;
  }
}

.slider-padding {
  padding-right: 50px !important;

  @media (max-width: 767px) {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
}

.routine-img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

.heading-sub {
  max-width: 1200px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  font-size: 52px;
  font-weight: 800;
  line-height: 120%;
}

.heading-item {
  margin-top: 0;
  font-size: 24px;
  font-weight: 400;
  line-height: 140%;
}

.text-details {
  text-align: center;
  text-transform: none;
  font-size: 18px;
  line-height: 25px;
}

.rotate-reverse {
  transform: rotate(180deg);
}

.line-height1-25 {
  line-height: 1.25;
  font-size: 11px;
}

.image-sizes {
  @media screen and (max-width: 600px) {
    top: 12% !important;
    right: 4% !important;
    height: 120px !important;
    width: 130px;
  }
}

.shila-bestvalue {
  position: absolute;
  top: -25%;
  bottom: auto;
  left: auto;
  right: 0%;

  @media screen and (max-width: 479px) {
    width: 160px;
    max-width: 90%;
    top: -6%;
    right: -5%;
  }
  @media screen and (max-width: 991px) {
    top: -7%;
  }
}

.image-sizes.shila {
  @media screen and (max-width: 991px) {
    height: 120px !important;
    width: 120px !important;
  }
}

.toggle-wrapper {
  line-height: 1;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.toggle-grid {
  display: grid !important;
  grid-template-columns: 13fr 1fr !important;

  @media (max-width: 575px) {
    grid-template-columns: 11fr 1fr !important;
  }
}

.vertical-align-super {
  vertical-align: super !important;
}

.rotate-180 {
  rotate: 180;
}

.features-right-2 {
  max-width: 56%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: -16px;
  display: flex;
  margin-left: 60px;

  @media screen and (max-width: 991px) {
    max-width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }

  @media (max-width: 479px) {
    margin-bottom: -30px;
    margin-left: 0;
    margin-bottom: 20px;
  }
}

.features-right-2.wrap {
  max-width: 66%;
}

.w-list-unstyled {
  padding-left: 0;
  list-style: none;
}

.key-img {
  width: 80px !important;
  height: 80px !important;
}

.features-block-two-2 {
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
  display: flex;

  @media screen and (max-width: 479px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 30px;
  }
}

.aloev-freebie-section {
  background-color: #f5f7fa;
  border-bottom: 1px solid #e4ebf3;
  padding: 80px 30px;
  position: relative;

  @media screen and (max-width: 767px) {
    padding: 60px 15px;
  }
}

.container-53 {
  width: 100%;
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 479px) {
    max-width: none;
  }

  @media screen and (max-width: 991px) {
    max-width: 728px;
  }
}

.hero-wrapper-4 {
  justify-content: space-between;
  align-items: center;
  display: flex;

  @media screen and (max-width: 991px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: -40px;
  }
}

.hero-split-5 {
  max-width: 46%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;

  @media screen and (max-width: 991px) {
    max-width: 100%;
    margin-bottom: 40px;
  }
}

.margin-bottom-24px-5 {
  margin-bottom: 24px;
}

.orange-button {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 15px;
}

.timerr {
  margin-bottom: 25px;
  @media (max-width: 550px) {
    text-align: center;
  }
}

.img-tag {
  position: absolute;
  top: -13%;
  bottom: auto;
  left: auto;
  right: 0%;
  z-index: 9999;

  @media screen and (max-width: 991px) {
    top: -7%;
  }
}

.orange-button {
  width: auto;
  height: auto;
  text-align: center;
  background-color: #ff6914;
  border-radius: 10px;
  margin-top: 20px;
  padding: 20px 30px;
  font-size: 20px;
  font-weight: 700;
  display: block;
}

.shadow-two-4 {
  box-shadow: 0 4px 24px rgba(150, 163, 181, 0.08);
}
