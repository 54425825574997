/* -------------------------------------------
	Widget 
------------------------------------------- */

@include set-default((widget: (title: (margin-bottom: 2rem,
                padding: 1.1rem 0,
                font-size: 1.6rem,
                font-weight: 700,
                text-transform: uppercase,
                line-height: 1.2,
                border-bottom: 1px solid $border-color,
                letter-spacing: .025em,
            ))));

.widget-title {
    @include print_css(widget, title);
}

.widget-products {
    .widget-title {
        padding: 2.8rem .3rem 1.6rem;
        font-size: 1.8rem;
        font-weight: 600;
        letter-spacing: .03em;
    }
}