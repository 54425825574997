/* -------------------------------------------
    Cart Page
---------------------------------------------- */

.cart {
  // .title {
  //     margin-bottom: 1.8rem;
  //     font-size: 1.6rem;
  //     letter-spacing: 0;
  //     line-height: 1;
  // }

  &.main {
    // border-top: 1px solid #e1e1e1;
  }

  .accordion {
    border-bottom: 1px solid $border-color;
  }

  .card-header {
    > a {
      padding-left: 2px;
    }

    font-size: 1.4rem;
  }

  .card-body {
    margin-bottom: 2.4rem;
    padding: 0;
  }

  .form-control {
    font-family: $font-family;
  }

  label {
    cursor: pointer;
  }

  .remove {
    margin-right: 0;
    width: 2rem;
    text-align: center;
  }

  .summary {
    line-height: 2.15;

    .title {
      margin-bottom: 1rem;
      padding-bottom: 1.8rem;
      border-bottom: 1px solid $border-color;
    }
  }

  // Button
  .btn-checkout {
    display: block;
    padding-top: 1.06em;
    padding-bottom: 1.06em;
    border-color: #333;
    background-color: #333;
  }

  .product-thumbnail {
    figure {
      position: relative;
      display: inline-block;
    }
  }

  .shop-table {
    th {
      padding: 0.2rem 0 1.8rem;
      font-size: 1.3rem;
      font-weight: 700;
      color: $grey-color;
      line-height: 1;
      letter-spacing: 0.025em;
    }

    .product-name-section {
      text-align: start;
    }

    td {
      &:last-child {
        padding-right: 0;
      }

      &:first-child {
        padding: 2rem 2rem 1.5rem 0;
      }
    }

    .quantity.form-control {
      font-weight: 700;
    }
  }

  .product-remove {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.2rem;
    width: 2.3rem;
    height: 2.3rem;
    border: 1px solid $light-color;
    border-radius: 50%;
    background-color: #fff;
    color: $dark-color;

    i {
      height: 12px;
      width: 12px;
      display: flex;
      align-self: center;
      justify-content: center;
    }
  }

  .shipping {
    margin-bottom: 0.3rem;
  }

  .btn {
    border-width: 1px;
  }

  .shipping-row-last {
    .summary-subtitle {
      padding: 2.4rem 0 1.9rem;
    }

    li {
      padding-bottom: 0.3rem;
    }
  }

  .discount-percentage {
    font-weight: 600;
    color: $success-color;
  }
}

.step-by {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0.9rem 0;

  .title.title-step {
    margin: 0 3.4rem 0 1rem;
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: 700;
    color: $body-color;
    letter-spacing: 0.1rem;
    padding: 0.4rem 0;

    @media (max-width: 575px) {
      margin: 0 2rem 0 0rem;
    }

    &:not(:last-child) i {
      display: inline-block;
      position: absolute;
      right: -3rem;
      top: 20%;
      line-height: 0;
      vertical-align: middle;
      font-family: "Font Awesome 5 Free";
      font-weight: 700;
      font-size: 1.7rem;
      color: $light-color;

      @media (max-width: 575px) {
        right: -1.5rem;
      }
    }

    &.visited {
      ~ .active {
        &::before {
          color: $dark-color;
        }
      }

      color: $dark-color;
    }

    &.active {
      color: $primary-color;
    }

    &:first-child {
      margin-left: 0;

      &::before {
        display: none;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    .step-by-btn {
      cursor: pointer;
    }
  }
}

.summary {
  padding: 1rem 3rem 1rem;
  border: 1px solid #e1e1e1;
  border-radius: 2px;

  td:last-child,
  th:last-child {
    text-align: right;
  }

  th {
    text-align: left;
  }

  .form-control {
    width: 100%;
    max-width: 100%;
  }
}

.summary2 {
  background-color: #f8f8f8 !important;
  border: none !important;
  padding: 0rem 3rem 0rem !important;

  @media (max-width: 767px) {
    padding: 0rem 2rem 0rem !important;
  }
}

.summary-saving-lable-container {
  padding: 2.5px 0px;
  margin: 1.2rem 0px 0px 0px;
  text-align: start;
  border-radius: 0.3rem;

  .saving-lable {
    margin: 0px;
    color: $success-color;

    span {
      font-weight: bold;
    }
  }
}

.qty-label {
  width: fit-content;
  border-radius: 14px;
  margin-top: 0;
}

.summary-title {
  font-weight: 700;
  letter-spacing: 0.01em;
  color: $dark-color;
  margin-bottom: 0;
}

.summary-title2 {
  border-bottom: none;
  padding-bottom: 10px !important;
  text-transform: none !important;
  font-size: 20px;

  @media (max-width: 500px) {
    font-size: 16px;
  }
}

.cart-product-padding {
  padding-bottom: 5px;
}

.button-padding {
  width: 80px !important;
  padding: 6px 50px !important;
  border-radius: 4px !important;
  font-size: 16px !important;

  @media (max-width: 500px) {
    width: max-content !important;
    padding: 8px 40px !important;
  }
}

.section-padding {
  padding: 0px 12px 12px 14px !important;
}

.overlay1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  max-width: 147px;
  max-height: 147px;

  @media (max-width: 500px) {
    max-width: 80px;
    max-height: 80px;
  }
}

.overlay-text1 {
  color: white;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  padding: 25px;

  @media (max-width: 500px) {
    font-size: 12px;
  }
}

.cart-sticky-checkout {
  padding: 12px 16px;
  border-top: 1px solid #ededed;
}

.td-alignment {
  vertical-align: top;
  padding-top: 15px;
}

.coupon-container {
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;
  padding: 12px;
  border-radius: 6px;
}
.product-carousal-heading {
  padding-bottom: 0 !important;
}
.coupon-heading {
  font-size: 20px;
  margin-bottom: 10px;
  position: sticky;
  top: 0;
  background: white;

  @media (max-width: 500px) {
    font-size: 16px;
  }
}

.padding-coupon-heading {
  padding: 16px 16px 0px 16px;
}

.apply-coupon-button {
  font-size: 16px;

  @media (max-width: 500px) {
    font-size: 14px;
  }
}

.view-offers-link {
  color: #17b31b;
  cursor: pointer;
  font-size: 14px !important;

  @media (max-width: 500px) {
    font-size: 12px !important;
  }
}

.applied-coupons-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

//?
.summary-subtotal {
  border-bottom: 1px solid #e1e1e1;
}

.summary-subtotal-saving {
  border-bottom: 1px solid #e1e1e1;
}

//?
.summary-subtitle {
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: 0.05em;
  color: $dark-color;
  margin-bottom: 0;
  padding: 1.5rem 0;

  p {
    color: $body-color;
    font-size: 1.2rem;
  }
}

.summary-subtotal-price {
  font-size: 1.4rem;
  font-weight: 600;
  color: $dark-color;
  margin-bottom: 0;
  font-family: $font-family;
}

.summary-subtotal-listingprice {
  color: #aaa;
}

.discount-price-color {
  color: $success-color;
}

.avg-delivery-lable-container {
  text-align: start;

  p {
    color: $body-color;
    font-size: 1.2rem;

    span {
      font-weight: 500;
      color: $primary-color;
    }
  }
}

.summary-total-price {
  letter-spacing: 0.025em;
  font-weight: 700;
  color: $dark-color;
  // padding: 10px 0;
  text-align: right;
  line-height: 1;
  margin-bottom: 0;
  font-family: $font-family;
  font-size: 2rem;
}

.paragraph-font {
  font-size: 12px !important;
}

.summary-total-price-2 {
  font-size: 14px;
}

.total .summary-subtitle {
  padding: 1.4rem 0 1.3rem;
}

.empty-cart {
  p {
    font-size: 1.6rem;
    font-weight: 600;
    color: #777;
  }

  i.cart-empty {
    margin-bottom: 3rem;
    font-size: 8rem;
    color: #aaa;
  }

  .btn.btn-md {
    border-radius: 2px;
    border: 2px solid #222;
    letter-spacing: 0.025em;
  }
}

@include mq(sm, max) {
  .cart .cart-actions {
    width: 100%;
    text-align: center;
    display: block;

    .btn-outline {
      margin-right: 2rem !important;
    }
  }

  .cart-coupon-box {
    .summary {
      padding: 2.4rem 3rem 0 !important;
    }
  }
}

@include mq("md") {
  .cart-table-wrapper {
    background-color: white !important;
    border: 1px solid #e1e1e1;
  }
}

@include mq(md, max) {
  .product-name-section {
    text-align: center !important;
    margin: 0px 15px;
  }

  .summary {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .cart-coupon-box {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .cart .shop-table td {
    padding: 0;

    &.product-price {
      display: block;
      width: 100%;
      text-align: center;
    }
  }

  .product-discount-listing {
    display: inline;
  }

  .cart .shop-table tr {
    padding: 2rem 0 3rem;
  }

  .cart .shop-table {
    .product-subtotal,
    .product-thumbnail {
      margin-bottom: 0.5rem;
    }
  }
}

.shipping-address {
  padding-bottom: 2.4rem;

  label {
    display: block;
    margin-bottom: 1.4rem;
    font-size: 1.5rem;
  }

  .form-control {
    margin-bottom: 1.5rem;
    padding: 1rem 1.4rem;
    font-size: 1.3rem;
    border-color: #e1e1e1;
    color: #999;
    min-height: 4.1rem;
  }

  border-bottom: 1px solid #e1e1e1;

  .btn {
    padding: 0.9em 1.7em;
  }
}

.shipping-address,
.checkout {
  input.form-control,
  .select-box {
    background: #fff;

    .form-control {
      padding: 1rem 1.4rem 0.8rem;
      font-size: 1.4rem;
      color: $body-color;
    }

    &::before {
      font-family: "riode";
      content: "\e953";
      right: 1.5rem;
      font-size: 1.1rem;
      z-index: 2;
    }
  }
}

.cart-total {
  text-align: right;

  .btn-calc {
    margin-bottom: 1.8rem;
  }

  td,
  th {
    border-top: 1px solid $border-color;
  }
}

.cart-subtotal {
  th {
    font-weight: 400;
  }

  td,
  th {
    padding: 1.5rem 0;
  }
}

.order-total {
  font-size: 1.6rem;
  font-weight: 600;
  color: $primary-color;

  td,
  th {
    padding-top: 1.9rem;
  }
}

.cart-table {
  .product-name {
    font-weight: 600;
    color: $dark-color;
    line-height: 1.86;
    padding-right: 2rem;
  }

  .product-price {
    width: 100px;
  }

  .product-quantity {
    width: 120px;

    .input-group {
      height: 3.5rem !important;
    }
  }

  .clear-cart {
    padding-top: 14px;
    padding-bottom: 12px;
    color: #000;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .cart-product-card {
    background-color: #f8f8f8;
    border-radius: 6px;
    font-family: Montserrat, sans-serif;
  }

  .cart-product-card.upsell {
    background-color: #fff;
    border: 1px dashed #17b31b;
  }

  @media (max-width: 767px) {
    .cart-product-card {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .d-flex.flex-column.font-weight-bold {
      text-align: center;
    }

    .btn-upsell {
      width: 100%;
      background-image: linear-gradient(#bd9a43, #bd9a43) !important;
      color: white;
    }
  }

  .image-container {
    position: relative;
    display: inline-block;
  }

  .svg-overlay {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-25%, -25%);
    z-index: 2;
  }

  .product-savings {
    color: #969696;
    font-size: 12px !important;
  }

  .mobile-specific-cart {
    padding: 20px 20px 20px 20px !important;

    @media (max-width: 500px) {
      padding: 12px 12px 12px 12px !important;
    }
  }

  .text-alignment {
    text-align: center !important;
  }

  .mobile-specific-cart-product-container {
    padding: 2rem 2rem 2rem 0.5rem;

    .form-control {
      height: 3.5rem !important;
      min-height: 3.5rem !important;
      padding: 0rem 1rem !important;
      font-size: 1.2rem;
      max-width: 110px;
      border: 1px solid #ccc !important;
    }

    .sm-product-amount {
      font-size: 1.5rem;
      font-weight: 500;
    }

    .sm-product-remove {
      position: absolute;
      right: 2rem;
      top: 2rem;
    }

    .outofstock-tag {
      background-color: rgb(249, 232, 232);
      color: #c20000;
      width: fit-content;
      padding: 0px 0.7rem;
      border-radius: 0.5rem;

      .outofstock-label {
        font-size: 1.5rem;
      }
    }

    .cart-product-title {
      overflow: hidden;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }

    .upsell {
      font-size: 14px !important;
      color: #bb360d;
    }

    .btn-upsell {
      padding: 0.8em 2.78em !important;
      background-image: linear-gradient(#bd9a43, #bd9a43) !important;
      color: white;
    }

    .circular-timer {
      position: relative;
      display: inline-block;
    }

    .timer-svg-container {
      position: relative;
      width: 100%;
      padding-bottom: 100%;
      margin: 0 auto;
    }

    .timer-svg {
      transform: rotate(-90deg);
    }

    .timer-circle {
      transition: stroke-dashoffset 1s linear;
    }

    .timer-text {
      position: absolute;
      top: 50%;
      left: 50%;
      color: #000 !important;
      transform: translate(-50%, -50%);
      font-size: 16px;
      font-weight: bold;
    }

    .ribbon.top-left {
      position: absolute;
      z-index: 98;
      width: 80px;
      height: 110px;
      overflow: hidden;
      top: -5px;
      left: -6px;

      > small {
        background-size: 100%;
        background-image: -webkit-gradient(
          linear,
          50% 0%,
          50% 100%,
          color-stop(0%, #5cb85c),
          color-stop(100%, #3d8b3d)
        );
        background-image: -moz-linear-gradient(top, #5cb85c 0%, #3d8b3d 100%);
        background-image: -webkit-linear-gradient(
          top,
          #5cb85c 0%,
          #3d8b3d 100%
        );
        background-image: linear-gradient(to bottom, #5cb85c 0%, #3d8b3d 100%);
        position: absolute;
        width: 100%;
        padding: 4px;
        text-align: center;
        font-size: 65%;
        color: #fff;
        background-color: #5cb85c;
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        -moz-box-shadow: 0 3px 6px -3px rgba(0, 0, 0, 0.5);
        -webkit-box-shadow: 0 3px 6px -3px rgba(0, 0, 0, 0.5);
        box-shadow: 0 3px 6px -3px rgba(0, 0, 0, 0.5);
        top: 12px;
        left: -20px;

        &::before {
          position: absolute;
          content: " ";
          bottom: -3px;
          left: 0px;
          border-top: 3px solid #163216;
          border-left: 3px solid transparent;
          border-right: 3px solid transparent;
        }

        &::after {
          position: absolute;
          content: " ";
          right: 0px;
          bottom: -3px;
          border-top: 3px solid #163216;
          border-left: 3px solid transparent;
          border-right: 3px solid transparent;
        }
      }
    }
  }
}
.small-product-remove {
  position: absolute;
  right: 1.5rem;
  top: 2rem;

  @media (max-width: 500px) {
    top: 1.4rem;
  }
}

.small-product-remove.limited-deal {
  top: 22px;

  right: 8px !important;
}

.product-subtotal {
  color: $dark-color;
}

.input-group {
  button,
  input {
    border-color: $border-color-light;
  }

  .quantity-minus {
    border-radius: 0.3rem 0 0 0.3rem;
    border: 1px solid #ccc;
  }

  .quantity-plus {
    border-radius: 0 0.3rem 0.3rem 0;
    border: 1px solid #ccc;
  }
}

.cart-actions {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;

  .btn-icon-left {
    padding: 1.06em 2.2em;

    i {
      margin-right: 0.8rem;
      font-size: 2rem;
    }
  }

  button {
    padding: 1.07em 2.23em;

    &.btn-disabled {
      border-color: $dark-color !important;
      background-color: transparent !important;
      border-width: 1px;
      color: $dark-color !important;
      opacity: 0.4;
      cursor: default;
    }
  }
}

.cart-coupon-modal {
  padding: 2rem;
  letter-spacing: 0.0225rem !important;

  // .d-flex {
  //   align-items: baseline;
  // }

  .coupon-tagline2 {
    color: #222;
    font-weight: bolder;
  }

  .coupon-apply-btn {
    min-width: 11rem;
    padding: 1.2rem 1.5rem !important;
  }

  .coupon-error-box-border {
    border: 1px solid $alert-color;
  }

  .coupon-error-lable {
    color: $alert-color;
  }

  .all-coupons-container {
    max-height: 550px;
    overflow: scroll;
  }

  .all-coupons-container::-webkit-scrollbar {
    display: none;
  }
}

.stick-bottom-button {
  width: 100vw;
  max-width: 500px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 35px;
  left: calc(50vw - 25rem);
  background: #fff;
  border-radius: 5px;
  z-index: 99;
  padding: 10px 0;
  box-shadow: 0 0 10px 1px rgb(0 0 0 / 10%);
  padding-left: 15px;
  padding-right: 15px;

  .btn {
    padding: 1em 2em;
  }
}

.floatingcta-ps {
  text-align: center;
  background-color: #fff;
  margin-top: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  display: block;
  position: fixed;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: 0%;
  width: 100vw;
  z-index: 99999;
}

.floatbuttoncta {
  background-image: linear-gradient(#7bb806, #279b35);
  border: 1px solid #02660c;
  border-radius: 10px;
  margin-top: 0;
  font-size: 20px;
  font-weight: 700;

  @media screen and (max-width: 767px) {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

.stick-bottom-button-order {
  padding: 0 !important;
}

.featured-coupon {
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e1e1e1;

  .featured-coupon-text-content {
    margin-right: 2rem;
  }
}

.featured-coupon-slider {
  padding: 1rem;
  border: 1px solid #969696;
  border-radius: 6px;
}

.featured-coupon-slider-applied {
  padding: 1rem;
  border: 1px solid #17b31b !important;
  border-radius: 6px;
}

.cart-coupon-box {
  display: flex;
  flex-direction: column;
  padding: 1.4rem 3rem;
  border: 1px solid #e1e1e1;
  border-radius: 2px;
  cursor: pointer;

  .cart-coupon-container {
    justify-content: space-between;
  }

  .coupon-title {
    margin-bottom: 1rem;
    padding-top: 0.8rem;
    font-size: 1.5rem;
    font-weight: 600;
  }

  .coupon-subtitle {
    color: $grey-color;
    font-weight: 600;
  }

  .coupon-offer {
    height: fit-content;
    white-space: nowrap;
    cursor: pointer;
    font-weight: 600;
  }
}

#coupon_code {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  font-size: 1.3rem;
  border: none;
  border-bottom: 1px solid $border-color;
  transition: border-color 0.3s;
  border-radius: 0;
}

@include mq("lg") {
  .cart.main {
    .product-thumbnail {
      width: 12rem;
    }

    .product-price {
      width: 11rem;
    }

    .product-subtotal {
      width: 13.2rem;
    }

    .product-close {
      width: 2.3rem;
    }
  }

  .coupon-modal-container {
    .auth-popup {
      width: 55rem;
    }
  }
}

@include mq("md") {
  .coupon-modal-container {
    .auth-popup {
      width: 55rem;
    }
  }
}

@include mq(sm, max) {
  .sm-container {
    margin-top: 0px !important;
  }

  .stick-bottom-button {
    width: 100vw !important;
    bottom: -1px !important;
    left: 0;
    border-radius: 0 !important;
  }

  .card-title {
    font-size: 1.6rem;
  }

  .checkout {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
  }
}

.cart-products {
  background-color: white !important;
  padding: 0px !important;
  margin-bottom: 20px !important;

  .product-title-ellipsis {
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .owl-stage {
    margin-left: 2px;
  }

  .capitalize-title {
    font-size: 1.7rem;
  }
}

.discount-card {
  background-color: #17b31b;
  border-radius: 3px;
  color: #fff !important;
  font-size: 12px;
  padding: 2px;
}

.variant-selection-form {
  background-color: #fff;
  border-radius: 4px;
  padding: 0 !important;
  font-size: 10px !important;
  width: 90%;
}

.variant-selection-form-small {
  background-color: #fff;
  border-radius: 4px;
  padding: 8px 12px !important;
  font-size: 10px !important;
  min-height: 35px !important;
}

.table-margin {
  margin-bottom: 98px;
  @media (max-width: 500px) {
    margin-bottom: 100px;
  }
}

.table-margin-applied {
  margin-bottom: 135px;

  @media (max-width: 500px) {
    margin-bottom: 190px;
  }
}

.limited-time-product {
  background-color: #fff7ce;
  margin-left: 11px;
  width: 95%;
}

.limited-time-added-product {
  border: 1px solid #9b9b9b;
  border-radius: 6px;
}

.limited-time-deal-tag {
  position: absolute;
  top: 1px;
  right: 1px;
  background-color: #ffe257;
  color: #222;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
}

.cart-added-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 15px;
}

.limited-time-deal-card {
  position: relative;
  border-radius: 6px;
}

.limited-time-product-card {
  position: relative;

  margin-left: 8px;
  width: 98%;

  @media (max-width: 500px) {
    margin-left: 1px;

    width: 98.5%;
  }
}

.limited-time-product-card-2 {
  position: relative;

  margin-left: 18px;
  width: 93%;

  @media (max-width: 500px) {
    margin-left: 12px;

    width: 93.5%;
  }
}

.font-success {
  font-size: 10px;
  color: #17b31b;
  align-self: center;
}

.limited-time-deal-overlay {
  position: absolute;
  top: -15px;
  left: 0;
  width: 95%;
  height: 30px;
  margin-left: 11px;
  background-color: #ffe257;
  border-radius: 6px;
  z-index: 1;
  align-items: center;
}

.limited-time-text {
  padding: 5px 8px 5px 8px;
  font-size: 12px;
  text-align: right;
}
.limited-time-text.align-left {
  font-size: 8px !important;
  text-align: left !important;

  @media (min-width: 500px) {
    padding-top: 8px;
    font-size: 10px !important;
  }
}

.grid-container {
  display: grid;
  grid-template-columns: 4fr 5fr;
}

.padding-coupon-container {
  padding: 12px 16px;
}

.box-shadow-coupon {
  box-shadow: 0px -3px 12px 0px rgba(0, 0, 0, 0.15);
}

.coupon-container2 {
  font-weight: bolder;
  padding: 14px;
  border-radius: 6px;
}

.coupon-container2-nocoupon-background {
  background: rgba(237, 237, 237, 1);
}

.coupon-container2-applied-background {
  background: rgba(23, 179, 27, 0.08);
}

.applied-coupon-modal {
  border-radius: 10px;
}

.applied-coupon-modal-container {
  width: 408px;
  height: 210px;
  border-radius: 10px;
  border: 3px solid #ffd613;
  background: #fff9d8;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 450px) {
    width: 280px;
    height: 130px;
  }
}

.applied-coupon-modal-image {
  position: absolute;
  margin-top: -150px;
  z-index: -1;
}

.applied-coupon-modal-ticket {
  position: absolute;
  height: 108px;
  width: 108px;
  margin-top: -56px;
  margin-right: -15px;
  z-index: 1;
  @media (max-width: 450px) {
    height: 92px;
    width: 92px;
    margin-top: -46px;
  }
}

.applied-coupon-modal-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20% 0 10%;
}
