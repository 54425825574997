@include set (
    (
        vendor: (
            details: (
                margin-bottom: 2rem
            ),
            logo: (
               margin-right: 1rem
            ),
            name: (
                margin-bottom: .5rem,
                font-weight: 600,
                font-size: 1.5rem,
                letter-spacing: .025em
            ),
            count: (
                font-weight: 400,
                font-size: 1.2rem,
                color: #666
            ),
            products: (
                grid: (
                    grid-template-columns: repeat(auto-fill, calc(100% / 3)),
                    _end-conumn: span 1,
                    _end-row: span 1
                )
            )
        ),
        store: (
            title: (
                display: inline-block,
                margin-bottom: 0,
                color: #fff,
                text-shadow: rgba(0, 0, 0, 0.8) 0 1px 0,
                font-size: 25px,
            ),
            label: (
                display: inline-block,
                margin-left: 1rem,
                padding: .4rem 1rem,
                font-size: 1rem,
                text-transform: uppercase,
                font-weight: 600,
                background: #2d54a3,
                color: #fff,
                border-radius: 3px,
                box-shadow: 0px 0px 25px -5px #afafaf,
            )
        )
    )
);

.vendor-details {
    display: flex;
    align-items: center;
    @include print_css ( vendor, details );
}

.vendor-logo {
    @include print_css ( vendor, logo );
}

.vendor-name {
    @include print_css ( vendor, name );
}

.vendor-products-count {
    @include print_css ( vendor, count );
}

.vendor-products {
    &.grid-type {
        display: grid;
        @include css ( grid-template-columns, vendor, products, grid, grid-template-columns );
        grid-auto-rows: 1fr;
        
        .vendor-product {
            @include css( grid-column-end, vendor, products, grid, _end-conumn );
            @include css( grid-row-end, vendor, products, grid, _end-row );
        }
    }
}
.store {
    position: relative;
    // box-shadow: 0px 0px 25px 0px #ddd;
    border-radius: .3rem;
    overflow: hidden;
    margin-bottom: 2rem;
    .store-content {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9;
        width: 100%;
        height: 100%;
        padding: 2rem 2.5rem;
        background: rgba(0, 0, 0, 0.45);
        color: #fff;
    }
    .store-title {
        display: flex;
        align-items: center;
        a {
            @include print_css ( store, title );
        }
    }
    .featured-label {
        width: max-content;
        width: -moz-max-content;
        width: -webkit-max-content;
        @include print_css ( store, label );
    }
    .ratings-container {
        margin: 0 0 1.1rem .2rem;
        font-size: 1.4rem;
        letter-spacing: .3em;
    }
    .ratings-full::before {
        content: '\e954''\e954''\e954''\e954''\e954';
        color: $secondary-color;
    }
    p {
        margin-bottom: .2rem;
        line-height: 1.65;
    }
    .store-phone {
        font-size: 1.3rem;
        i {
            margin: 0 .3rem .4rem 0;
            font-size: 1.1rem;
            transform: rotateZ(93deg);
            vertical-align: middle;
        }
    }
    .store-footer {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 2.5rem 2rem 2.4rem;
        background: #fff;
        z-index: 90;
    }
    .seller-avatar {
        width: 7rem;
        height: 7rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: .6rem;
        position: absolute;
        background: #fff;
        right: 2.5rem;
        bottom: 6rem;
        overflow: hidden;
        box-shadow: 0px 0px 30px -6px #afafaf;
        img {
            border-radius: 50%;
        }
    }
    .btn-link {
        text-transform: capitalize;
        i {
            font-size: 2rem;
        }
    }
}

// List type
.store-list {
    display: flex;
    align-items: center;
    .store-header {
        position: relative;
        .featured-label {
            position: absolute;
            top: 1rem;
            left: 1.5rem;
            margin-left: 0;
            padding-top: .3rem;
            padding-bottom: .3rem;
        }
    }
    .store-content {
        position: relative;
        background-color: #fff;
    }
    .store-title a {
        color: #222;
    }
    .store-footer {
        position: relative;
    }
}