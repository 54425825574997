/* -------------------------------------------
    Buttons
        - Button
            - Default
            - Solid
        - Outline Button
        - Icon Button
            - Icon Button
            - Reveal Button
            - Slide Button
        - Shadow Button
        - Underline Button
        - Link Button
        - Other Button Styles
        - Button Color Styles
---------------------------------------------- */

// Button
.btn {
  display: inline-block;
  border: 2px solid #e4eaec;
  background-color: #e4eaec;
  color: #222;
  outline: 0;
  padding: 1.22em 2.78em;

  font: {
    weight: 700;
    size: 1.4rem;
    family: $font-family;
  }

  letter-spacing: 0.1rem;
  line-height: 1.2;
  text-transform: uppercase;
  text-align: center;
  transition: color 0.3s, border-color 0.3s, background-color 0.3s,
    box-shadow 0.3s;
  white-space: nowrap;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    color: #222;
    border-color: darken(#e4eaec, 7%);
    background-color: darken(#e4eaec, 7%);
  }

  i {
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.4rem;
    line-height: 0;
    font-size: 1.9rem;

    &::before {
      margin: 0;
    }
  }
}

.btn-solid {
  background-color: #fff;
  border-color: #fff;
  color: #222;

  &:hover,
  &:active,
  &:focus {
    border-color: #222;
    background-color: #222;
    color: #fff;
  }
}

// Outline Button
.btn-outline {
  border: 2px solid #e4eaec;
  color: #666;
  background-color: transparent;

  &:hover,
  &:active,
  &:focus {
    border-color: #666;
    background-color: #666;
    color: #fff;
  }

  &.btn-outline-light {
    padding: 1.07em 2.21em;
    border-width: 1px;
  }

  &.btn-bg {
    color: #fff;
  }
}

// Icon Button
.btn-icon-left,
.btn-icon-right,
.btn-reveal-left,
.btn-reveal-right {
  // display: inline-flex;
  // align-items: center;
  i {
    line-height: 0;
  }
}

.btn-icon-right {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  i {
    margin-left: 0.7rem;
  }
}

.btn-icon-left {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  i {
    margin-right: 0.7rem;
    margin-left: 0;
  }
}

// Reveal Button
.btn-reveal-left {
  i {
    opacity: 0;
    margin-left: -1em;
    transform: translateX(0.5em);
    margin-right: 0;
    transition: transform 0.3s, opacity 0.3s, margin 0.3s;
  }

  &:hover,
  &:active,
  &:focus {
    i {
      opacity: 1;
      margin-left: 0;
      transform: translateX(0);
      margin-right: 0.7rem;
    }
  }
}

.btn-reveal-right {
  i {
    opacity: 0;
    margin-right: -1em;
    transform: translateX(-0.5em);
    margin-left: 0;
    transition: transform 0.3s, opacity 0.3s, margin 0.3s;
  }

  &:hover,
  &:active,
  &:focus {
    i {
      opacity: 1;
      margin-left: 0.7rem;
      transform: translateX(0);
      margin-right: 0;
    }
  }
}

// Slide Button
.btn-slide-left {
  &:hover,
  &:active,
  &:focus {
    i {
      animation: 0.5s ease slideLeft;
    }
  }
}

.btn-slide-right {
  &:hover,
  &:active,
  &:focus {
    i {
      animation: 0.5s ease slideRight;
    }
  }
}

.btn-slide-up {
  &:hover,
  &:active,
  &:focus {
    i {
      animation: 0.5s ease slideUp;
    }
  }
}

.btn-slide-down {
  &:hover,
  &:active,
  &:focus {
    i {
      animation: 0.5s ease slideDown;
    }
  }
}

.btn-infinite {
  &:hover {
    i {
      animation-iteration-count: infinite;
    }
  }
}

@keyframes slideLeft {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-0.5em);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideRight {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(0.5em);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-0.5em);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(0.5em);
  }

  100% {
    transform: translateY(0);
  }
}

// Shadow Button
.btn-shadow-lg {
  box-shadow: 0 13px 20px -10px rgba(0, 0, 0, 0.15);

  &:hover,
  &:active,
  &:focus {
    box-shadow: 0 13px 20px -10px rgba(0, 0, 0, 0.2);
  }
}

.btn-shadow {
  box-shadow: 0 15px 20px -15px rgba(0, 0, 0, 0.15);

  &:hover,
  &:active,
  &:focus {
    box-shadow: 0 15px 20px -15px rgba(0, 0, 0, 0.2);
  }
}

.btn-shadow-sm {
  box-shadow: 0 17px 20px -20px rgba(0, 0, 0, 0.15);

  &:hover,
  &:active,
  &:focus {
    box-shadow: 0 17px 20px -20px rgba(0, 0, 0, 0.2);
  }
}

// Underline Button
.btn-underline {
  &:hover,
  &:active,
  &:focus {
    &::after {
      transform: scaleX(1);
    }
  }

  &::after {
    display: block;
    margin-top: 1px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 2px;
    border: 1px solid;
    transform: scaleX(0);
    transition: transform 0.3s;
    content: "";
  }

  // i {
  //     margin-bottom: 2px;
  // }
  &.btn-underline-visible {
    &::after {
      transform: scaleX(1);
    }
  }

  &.btn-underline-width-sm {
    &::after {
      height: 1px;
      border-bottom: 0;
    }
  }
}

.btn-underline.sm::after {
  width: 46%;
}

.btn-underline.lg::after {
  margin-left: -16.5%;
  margin-right: -16.5%;
  width: 133%;
}

// Link Button
.btn-link {
  padding: 0;
  color: #222;
  border: 0;
  background-color: transparent;

  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
  }
}

// Other Button Styles
.btn-disabled {
  cursor: not-allowed !important;
  background-color: #e4eaec !important;
  color: #999 !important;
  border-color: #e4eaec !important;
  box-shadow: none !important;

  &::before,
  &::after {
    content: none !important;
  }

  &.btn-link {
    background-color: transparent !important;
  }
}

.btn {
  &.btn-rounded {
    border-radius: 3px;

    &::before,
    &::after {
      border-radius: 3px;
    }
  }

  &.btn-block {
    display: block;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }

  &.btn-lg {
    padding: 1.5em 3.42em;
  }

  &.btn-md {
    padding: 1.07em 2.15em;
    font-size: 1.3rem;
  }

  &.btn-sm {
    padding: 0.92em 1.61em;
    font-size: 1.3rem;
  }
}

.btn.btn-link {
  padding: 0;

  @include only-for-ie() {
    overflow: visible;
  }
}

.apply-button {
  background-color: transparent;
  border: none;
  font-size: 16px;
  color: #969696;
  cursor: pointer;
}

// 8. Button Color Styles
.btn-primary {
  @include button-variant($primary-button-color);
}

.btn-acv {
  background-image: linear-gradient(#7bb806, #279b35) !important;
  border: 1px solid #02660c !important;
  color: #fff;
  font-weight: 800;
}

.btn-acv.wrap {
  width: 100%;
}

.btn-secondary {
  @include button-variant($secondary-color);
}

.btn-alert {
  @include button-variant($alert-color);
}

.btn-success {
  @include button-variant($success-color);
}

.btn-dark {
  color: #fff !important;
  @include button-variant($dark-color);
}

.btn-white {
  color: $dark-color;
  background-color: #fff;
  border-color: #fff;

  &:hover,
  &:active,
  &:focus {
    color: $white-color;
    border-color: $primary-color;
    background-color: $primary-color;
  }

  &.btn-outline {
    color: #fff;
    background-color: transparent;
    border-color: #fff;

    &:hover,
    &:active,
    &:focus {
      color: $primary-color;
      background-color: #fff;
    }
  }

  &.btn-link {
    color: #fff;
    background-color: transparent;

    &:hover,
    &:active,
    &:focus {
      color: #fff;
      background-color: transparent;
    }
  }
}
