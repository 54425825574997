/* -------------------------------------------
    Alert
        - Default
        - Simple Alert
        - Dark Alert
        - Icon Alert
        - Light Alert
        - Message Alert
        - Button Alert
        - Summary Alert
---------------------------------------------- */

// Alert Radius
.alert-round {
  border-radius: 3px;
}

// Default
.alert {
  position: relative;
  padding: 1rem 1.8rem 1rem 1.8rem;
  border: 1px solid #222;
  font-size: 1.4rem;
  line-height: 1.75;

  .btn-close {
    display: inline-block;
    position: absolute;
    top: 2.3rem;
    right: 1rem;
    transform: translateY(-50%);
    margin-top: -0.1rem;
    font-size: 1.5rem;
    line-height: 0;
  }
}

.alert-title {
  font-size: 1.4rem;
  line-height: inherit;
  color: inherit;
  font-weight: 400;
}

.btn-close {
  transition: color 0.3s;

  &:hover {
    color: #000;
  }
}

// alert yellow

.alert-transparent {
  color: $primary-color !important;
  background: $primary-button-color !important;
  border: $primary-button-color !important;
}

// Color types
.alert-primary {
  background-color: $info-color;
  border-color: $info-color;
  color: $primary-color;
  // .btn-close { color: $primary-color; }
}

.alert-success {
  background-color: $success-color;
  border-color: $success-color;
  color: $success-color;
  // .btn-close { color: $success-color; }
}

.alert-warning {
  background-color: $warning-color;
  border-color: $warning-color;
  color: $warning-color-dark !important;
  //.btn-close { color: $secondary-color; }
}

.alert-danger {
  background-color: $alert-color;
  border-color: $alert-color;
  color: $alert-color;
  //.btn-close { color: $alert-color; }
  // text-align: center;
}

.alert-black {
  background-color: #222;
  border-color: #222;

  .btn-close:hover {
    color: #666;
  }
}

//  Simple
.alert-simple {
  background-color: transparent;
  border-style: dashed;
  border-color: #cacbcc;
}

//   Dark
.alert-dark {
  color: #fff;

  .btn-close {
    color: #fff;
  }
}

//  Icon
.alert-icon {
  padding: 1.5rem 3.9rem 1.5rem 1.9rem;

  > i {
    vertical-align: middle;
    margin-right: 1.2rem;
    line-height: 1;

    font: {
      size: 2.4rem;
      weight: 600;
    }
  }
}

//  Light
.alert-light {
  &.alert-primary {
    background-color: #edf4f9;
    border-color: #edf4f9;
  }

  &.alert-danger {
    background-color: #f6d6d5;
    border-color: #f6d6d5;
    color: #6d1a17;

    .btn-close {
      color: #6d1a17;
    }
  }

  &.alert-warning {
    background-color: #fff5eb;
    border-color: #fff5eb;
  }
}

// Message
.alert-message {
  display: block;
  padding: 1.9rem 3.9rem 1.9rem 1.9rem;

  .btn-close {
    position: absolute;
    transform: translateY(0);
    top: 2.7rem;
    right: 2.1rem;
    font-size: 1.4rem;
  }

  .alert-title {
    margin-bottom: 0.5rem;
    font-weight: 700;
    line-height: 1.7;
  }

  p {
    margin: 0;
    padding-right: 2rem;
    line-height: 1.7;
  }

  &.alert-link {
    p {
      color: #666;
    }

    a {
      color: $primary-color;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.alert.alert-inline {
  .alert-title {
    display: inline-block;
    margin: 0 0 0 0;
  }

  &.error-height {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  p {
    display: inline;
  }
}

//  Buttons
.alert-btn {
  &:not(.alert-message) {
    padding: 0.9rem 3.9rem 0.9rem 0.9rem;
  }

  .btn:not(.btn-close) {
    margin-right: 1rem;
    padding: 0.73em 1.61em;
  }

  &.alert-message {
    p {
      margin-bottom: 1rem;
    }
  }

  &.alert-dark {
    &.alert-warning {
      .btn:not(.btn-close) {
        background-color: transparent;
        padding: 0.7em 1.43em;
        border-width: 3px;
        color: #fff;

        &:hover {
          border-color: #df7f50;
          background: #df7f50;
        }
      }
    }
  }

  &.alert-light {
    &.alert-warning {
      .btn:not(.btn-close) {
        color: $secondary-color;
        background-color: #fff;
        border-color: #fff;

        &:hover {
          color: #fff;
          background-color: $secondary-color;
          border-color: $secondary-color;
        }
      }
    }
  }
}

.coupon-tag2 {
  background: white;
  width: fit-content;
  padding: 0.5rem 1.5rem;
  border: 1px dashed #8d8d8d;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
}

.coupon-modal-tag-color {
  background: #EAEAEA;
}

.applied-coupon-tag {
  background-color: white;
  width: fit-content;
  padding: 0.5rem 1.5rem;
  border: 1px dashed #17b31b;
  border-radius: 0.5rem;
  color: #17b31b;
  display: flex;
  align-items: center;
}

.coupon-status-applied {
  color: #17B31B;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0.5rem 0.5rem;
  display: flex;
  align-items: center;
}

.coupon-applied-confetii{
    margin: -15px auto
}

.coupon-message {
  color: #969696;
  font-size: 1.05rem;
  width: 78%;
  line-height: normal;
  padding-top: 0.7rem;
}

.coupon-apply-button {
  padding: 6px 15px !important;
  font-size: 14px !important;
  font-weight: bolder !important;
}

.coupon-applied-text {
  color: #17b31b;
}

.disabled-coupon {
  border-color: #d9d9d9 !important;
  background-color: #d9d9d9 !important;
}

.coupon-block-width {
  width: 100% !important;
}

.coupon-error-lable {
  color: #E50B20 !important;
}

.coupon-error-box-border {
  border: 1px solid $alert-color;
}

.coupon-slider {
  position: fixed;
  top: 0;
  right: -100%;
  width: 80%;
  height: 100%;
  background-color: white;
  transition: right 0.3s ease-in-out;
  z-index: 1000;
  width: 100vw;
  overflow-y: auto;

  @media (min-width: 575px) {
    max-width: 50rem !important;
  }
}

.coupon-input {
  background-color: #eaeaea;
  border: none !important;
  border-radius: 6px !important;
}

.coupon-slider.open {
  padding: 0px 20px 20px 20px;
  right: 0;
}

.cart-title {
  margin-bottom: 0;
  font-size: 20px !important;
  line-height: 28px !important;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.05em;

  @media (max-width: 500px) {
    font-size: 16px !important;
  }
}

.slider-header {
  padding: 14px 0px 14px !important;
  border-bottom: 1px solid #969696;
  font-size: 20px;
}

.alert-summary {
  > i {
    margin-right: 0.9rem;
    font-size: 1.6rem;
    line-height: 1.25;
  }

  ul {
    margin: 0rem 0 0 2.7rem;
    list-style-type: disc;
  }
}

.coupon-tagline2 {
  color: #222;
  font-weight: bolder;
  width: 78%;
  line-height: normal;
}

.coupon-remove {
  font-size: 16px;
}

.label-size {
  font-size: 1.3rem;
}

.background-coupon-white {
  background: white;
  color: green
}